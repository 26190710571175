import {createApp} from 'vue';
import Toast from 'vue3-toast-single';
import {VueQueryPlugin} from 'vue-query';

import App from './App.vue';
import i18n from './i18n';
import router from './router';

createApp(App)
  .use(Toast, {
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    duration: 3000,
    transition: 'slide-right'
  })
  .use(router)
  .use(VueQueryPlugin)
  .use(i18n)
  .mount('#app');
