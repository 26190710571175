import {generateQueryParams} from '@/helpers/url';

import $http from '@/utils/https';

const API_URL = `${process.env.VUE_APP_APIGW_URL}/chatbots/manager`;

export default {
  async fetch(queryParams) {
    const response = await $http(
      `${API_URL}/list${queryParams ? generateQueryParams(queryParams) : '?offset=0&limit=1000'}`
    );
    return response.data.data;
  },
  async fetchById(payload) {
    const response = await $http(`${API_URL}${generateQueryParams(payload)}`);
    return response.data.data;
  },
  async create({saleManagerId = null, ...other}) {
    const response = await $http.post(`${API_URL}${saleManagerId ? generateQueryParams({saleManagerId}) : ''}`, other);
    return response.data.data;
  },
  async update(data) {
    const response = await $http.patch(`${API_URL}?id=${data.id}`, data);
    return response.data.data;
  },
  async remove({id}) {
    await $http.delete(`${API_URL}?id=${id}`);
    return id;
  }
};
