import {createI18n} from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

function loadLocaleMessages() {
  const locales = require.context('./locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

// eslint-disable-next-line
const currentLocale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
const availableLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE;
let locale = '';
if (availableLocales.includes(currentLocale)) {
  locale = currentLocale;
} else {
  locale = process.env.VUE_APP_I18N_LOCALE;
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: locale || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
});
