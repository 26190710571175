<template>
  <teleport to="head">
    <title>{{ meta.pageTitle }}</title>
  </teleport>
</template>
<script setup>
import {computed} from 'vue';
import {useRouter} from 'vue-router';

const router = useRouter();
const meta = computed(() => router.currentRoute.value.meta);
</script>
