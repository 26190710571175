<template>
  <div class="package-upgrade">
    <div class="package-upgrade__wrap">
      <p class="package-upgrade__text">
        {{ packageUpgradeDialog?.text }}
      </p>
      <div class="d-flex justify-center mt-3">
        <BaseButton @on-click="navigateToPackages">
          {{ $t('upgrade') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import BaseButton from '@/components/atoms/BaseButton.vue';

import useEventsBus from '@/helpers/eventBus';

import 'swiper/swiper-bundle.css';
import {computed, defineProps} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const {bus, emitBus} = useEventsBus();
const router = useRouter();
const route = useRoute();

defineProps({
  shouldPackageUpgradeMore: {
    type: Boolean
  }
});

const packageUpgradeDialog = computed(() => {
  const packageUpgradeDialog = bus.value.get('packageUpgradeDialog');
  if (!packageUpgradeDialog) return {};
  const [packageUpgrade] = packageUpgradeDialog;
  if (packageUpgrade) {
    return packageUpgrade;
  }
  return {};
});

const navigateToPackages = () => {
  router.push({name: 'License', params: route.params, query: {...route.query, tab: 'serviceStore'}});
  emitBus('packageUpgradeDialog', {
    show: false
  });
};
</script>
<style lang="scss">
.swiper-pagination {
  bottom: 2px !important;
}

.swiper-pagination-bullet-active {
  background: $primary !important;
}


.package-upgrade {
  .swiper {
    padding-bottom: 20px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  &__text {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
  }

  &__item {
    border-radius: 5px;
    background: $silver-3;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-more_info {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 10px;

      a {
        color: $primary;
      }
    }

    &-bottom {
      margin-top: 20px;
    }

    h4 {
      margin-bottom: 10px;
      position: relative;
      text-align: center;
      font-size: 20px;
    }

    li {
      font-size: 14px;
      position: relative;
      padding-left: 12px;
      margin-bottom: 4px;
      line-height: 1.3;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
}
</style>
