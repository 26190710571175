export function useLocalStorage() {
  const isDemoMode = localStorage.getItem('demo-mode') === 'true';
  const setDemoMode = (status = 'false') => localStorage.setItem('demo-mode', status);
  const hasAccessToken = !!localStorage.getItem('accessToken');
  return {
    hasAccessToken,
    isDemoMode,
    setDemoMode
  };
}
