import bankIco from '@/assets/images/icons8-bank-account-90.png';
import paypalIco from '@/assets/images/icons8-paypal-an-american-company-operating-a-worldwide-online-payments-system-96.png';
import payoneerIco from '@/assets/images/svg/icons8-payoneer.svg';
import stripeIco from '@/assets/images/svg/icons8-stripe.svg';
import i18n from '@/i18n';
import {invert} from 'lodash';

const MessangerTypes = {
  POINT_OF_SALE: 0, // not a messenger
  TELEGRAM: 1,
  FACEBOOK: 2,
  WHATSAPP: 3,
  VIBER: 4
};

export const connectionCount = 8;

export const OrderPaymentStatus = {
  PENDING: 0,
  CONFIRMED: 1,
  REJECTED: 2,
  CANCELLED: 3,
  REFUNDED: 4,
  USER_READY_TO_PAY: 5
};

export const deliveryMethods = {
  PICKUP: 0,
  OWN: 1,
  DESPAXO: 2,
  GLOBAL: 3
};

export const deliveryMethodsLabels = {
  [deliveryMethods.PICKUP]: i18n.global.t('customerPickup'),
  [deliveryMethods.OWN]: i18n.global.t('own'),
  [deliveryMethods.DESPAXO]: i18n.global.t('despaxo'),
  [deliveryMethods.GLOBAL]: i18n.global.t('global')
};


export const discountTypes = {
  percentage: "percentage",
  fixed: "fixed"
}

export const deliveryMethodsList = [
  {
    id: deliveryMethods.PICKUP,
    name: i18n.global.t('pickup')
  }
  // {
  //   id: deliveryMethods.OWN,
  //   name: i18n.global.t('own')
  // }
];

const OrderProgressStatus = {
  IN_PROCESSING: 0,
  IN_QUEUE: 1,
  IN_PROGRESS: 2,
  IN_DELIVERY: 3,
  DELIVERED: 4
};

export const ServiceTypes = {
  UNIQUE_REQUIREMENTS_REQUEST: 0,
  SUPPORT_SERVICE_REQUEST: 1,
  DEVELOPMENT_REQUEST: 2,
  INDIVIDUAL_CONSULTATIONS: 3,
  DEVELOPER_CALL: 4,
  ANTI_DDOS_24_7: 5,
  SEO_OPTIMIZATION: 6,
  CONVERSION_INCREASE_SERVICE: 7,
  BUSINESS_AD_IN_MESSENGERS: 8,
  WITHDRAWAL_REQUEST: 9,
  COMMISSION_OR_PENALTY: 10,
  AMNESTY_PENALTY_OR_FINES_REQUEST: 11
};

export const addonsServices = Object.entries(ServiceTypes).reduce((acc, item) => {
  const [key, value] = item;
  acc.push({
    id: value,
    value,
    title: key
  });

  return acc;
}, []);
export const providers = [
  {
    id: MessangerTypes.POINT_OF_SALE,
    type: 'pointOfSale',
    label: i18n.global.t('pointOfSaleProvider'),
    logo: require('@/assets/images/providers/point_of_sale-ico.png')
  },
  {
    id: MessangerTypes.TELEGRAM,
    type: 'telegram',
    label: i18n.global.t('telegramProvider'),
    logo: require('@/assets/images/providers/telegram-ico.png')
  },
  {
    id: MessangerTypes.WHATSAPP,
    type: 'whatsapp',
    label: i18n.global.t('whatsappProvider'),
    logo: require('@/assets/images/providers/whatsapp-ico.png')
  }
];

export const orderStatuses = [
  {
    id: OrderProgressStatus.IN_PROCESSING.toString(),
    type: 'processing',
    label: i18n.global.t('orderStatusInProcessing'),
    color: '#c7c7c7'
  },
  {
    id: OrderProgressStatus.IN_QUEUE.toString(),
    type: 'pending',
    label: i18n.global.t('orderStatusPending'),
    color: '#fa9441'
  },
  {
    id: OrderProgressStatus.IN_PROGRESS.toString(),
    type: 'progress',
    label: i18n.global.t('orderStatusProcessing'),
    color: '#02c4fc'
  },
  {
    id: OrderProgressStatus.IN_DELIVERY.toString(),
    type: 'picked-up',
    label: i18n.global.t('orderStatusPickedUp'),
    color: '#78d25b'
  },
  {
    id: OrderProgressStatus.DELIVERED.toString(),
    type: 'delivered',
    label: i18n.global.t('orderStatusDelivered'),
    color: '#000000'
  }
  // {
  //   id: 4,
  //   type: 'closed',
  //   label: i18n.global.t('orderStatusClosed'),
  //   color: '#8a8a8a'
  // },
  // {
  //   id: 3,
  //   type: 'canceled',
  //   label: i18n.global.t('orderStatusCanceled'),
  //   color: '#f75757'
  // },
];

export const defaultFaqQuestion = [
  {
    question: i18n.global.t('defaultFaqQuestion1')
  },
  {
    question: i18n.global.t('defaultFaqQuestion2')
  },
  {
    question: i18n.global.t('defaultFaqQuestion3')
  },
  {
    question: i18n.global.t('defaultFaqQuestion4')
  },
  {
    question: i18n.global.t('defaultFaqQuestion5')
  },
  {
    question: i18n.global.t('defaultFaqQuestion6')
  }
];

export const msgTypes = {
  image: 'm.image',
  video: 'm.video',
  sticker: 'm.sticker',
  text: 'm.text',
  file: 'm.file',
  replace: 'm.replace',
  location: 'm.location',
  notice: 'm.notice',
  gif: 'm.gif',
  read: 'm.read',
  audio: 'm.audio'
};

export const eventTypes = {
  message: 'm.room.message',
  member: 'm.room.member',
  avatar: 'm.room.avatar',
  redaction: 'm.room.redaction',
  create: 'm.room.create',
  topic: 'm.room.topic',
  name: 'm.room.name'
};

export const chatNamesForBridge = {
  telegram: 'tg_bridge_bot',
  whatsapp: 'wa_bridge_bot'
};

export const messengerProviders = {
  telegram: 'telegram',
  whatsapp: 'whatsapp',
  instagram: 'instagram',
  messenger: 'messenger',
  discord: 'discord',
  signal: 'signal',
  slack: 'slack',
  line: 'line',
  viber: 'viber'
};

export const selfMembershipTypes = {
  invite: 'invite',
  join: 'join',
  leave: 'leave'
};

export const roles = {
  ADMIN: 0,
  SUPPORT: 1,
  ORDERS: 2,
  GENERAL_MANAGER: 3,
  BRANCH_REPRESENTATIVE: 4,
  SALES_REPRESENTATIVE: 6,
  DRIVER: 7,
  PROCESSING: 8,
  ACCOUNTANT: 9,
  VENTRIX_SUPPORT: 10,
  OWNER: 9999
};

export const dayList = [
  {
    id: 1,
    name: i18n.global.t('monday'),
    short: 'monday',
    to: 'mondayTo',
    from: 'mondayFrom',
    breakFrom: 'mondayBreakFrom',
    breakTo: 'mondayBreakTo',
    aroundClockServer: 'monday_24h',
    aroundClock: 'monday24H'
  },
  {
    id: 2,
    name: i18n.global.t('tuesday'),
    short: 'tuesday',
    to: 'tuesdayTo',
    from: 'tuesdayFrom',
    breakFrom: 'tuesdayBreakFrom',
    breakTo: 'tuesdayBreakTo',
    aroundClockServer: 'tuesday_24h',
    aroundClock: 'tuesday24H'
  },
  {
    id: 3,
    name: i18n.global.t('wednesday'),
    short: 'wednesday',
    to: 'wednesdayTo',
    from: 'wednesdayFrom',
    breakFrom: 'wednesdayBreakFrom',
    breakTo: 'wednesdayBreakTo',
    aroundClockServer: 'wednesday_24h',
    aroundClock: 'wednesday24H'
  },
  {
    id: 4,
    name: i18n.global.t('thursday'),
    short: 'thursday',
    to: 'thursdayTo',
    from: 'thursdayFrom',
    breakFrom: 'thursdayBreakFrom',
    breakTo: 'thursdayBreakTo',
    aroundClockServer: 'thursday_24h',
    aroundClock: 'thursday24H'
  },
  {
    id: 5,
    name: i18n.global.t('friday'),
    short: 'friday',
    to: 'fridayTo',
    from: 'fridayFrom',
    breakFrom: 'fridayBreakFrom',
    breakTo: 'fridayBreakTo',
    aroundClockServer: 'friday_24h',
    aroundClock: 'friday24H'
  },
  {
    id: 6,
    name: i18n.global.t('saturday'),
    short: 'saturday',
    to: 'saturdayTo',
    from: 'saturdayFrom',
    breakFrom: 'saturdayBreakFrom',
    breakTo: 'saturdayBreakTo',
    aroundClockServer: 'saturday_24h',
    aroundClock: 'saturday24H'
  },
  {
    id: 7,
    name: i18n.global.t('sunday'),
    short: 'sunday',
    to: 'sundayTo',
    from: 'sundayFrom',
    breakFrom: 'sundayBreakFrom',
    breakTo: 'sundayBreakTo',
    aroundClockServer: 'sunday_24h',
    aroundClock: 'sunday24H'
  }
];

export const businessLevelTypes = {
  small: 0,
  medium: 1,
  big: 2
};

export const businessType = {
  product: 1,
  service: 2
};
export const businessTypes = [
  {
    id: businessType.product,
    name: i18n.global.t('businessTypeProduct')
  },
  {
    id: businessType.service,
    name: i18n.global.t('businessTypeService')
  }
];

export const appointmentModes = [
  {
    id: 1,
    name: i18n.global.t('appointmentModeOrderForm'),
    availableForBusinessType: businessType.product
  },
  {
    id: 2,
    name: i18n.global.t('appointmentModeOnline'),
    availableForBusinessType: businessType.service
  },
  {
    id: 3,
    name: i18n.global.t('appointmentModeCalendar'),
    availableForBusinessType: businessType.service
  }
];

export const UnitType = {
  minute: 0,
  hour: 1,
  day: 2
};

export const unitTypes = [
  {
    id: 0,
    unit: UnitType.minute,
    name: i18n.global.t('minute')
  },
  {
    id: 1,
    unit: UnitType.hour,
    name: i18n.global.t('hour')
  },
  {
    id: 2,
    unit: UnitType.day,
    name: i18n.global.t('day')
  }
];

export const orderStatusFilters = [
  {
    id: 1,
    value: 'all',
    title: i18n.global.t('all')
  },
  {
    id: 8,
    value: 'assigned',
    title: i18n.global.t('assigned'),
    badge: true
  },
  {
    id: 2,
    value: 'new',
    title: i18n.global.t('new')
  },
  {
    id: 3,
    value: 'in_progress',
    title: i18n.global.t('inProgress')
  },
  {
    id: 6,
    value: 'completed',
    title: i18n.global.t('completed')
  },
  {
    id: 7,
    value: 'failed',
    title: i18n.global.t('failed')
  }
];

export const disputeStatusFilter = [
  {
    id: 1,
    value: 'cannot_process',
    title: i18n.global.t('cannotProcess')
  },
  {
    id: 2,
    value: 'problem',
    title: i18n.global.t('problem')
  }
];

export const lifetimeList = [
  {
    value: 30 * 24 * 60 * 60,
    title: 1
  },
  {
    value: 2 * 30 * 24 * 60 * 60,
    title: 2
  },
  {
    value: 3 * 30 * 24 * 60 * 60,
    title: 3
  },
  {
    value: 6 * 30 * 24 * 60 * 60,
    title: 6
  },
  {
    value: 12 * 30 * 24 * 60 * 60,
    title: 12
  },
  {
    value: 18 * 30 * 24 * 60 * 60,
    title: 18
  },
  {
    value: 24 * 30 * 24 * 60 * 60,
    title: 24
  }
];

export const PaymentTypes = {
  CASH: 0,
  CASH_DEBIT: 1,
  ONLINE: 2,
  XPR: 3,
  CONTACT_ME: 4,
  INSURANCE: 5,
  REWARD: 6
};

export const PaymentTypesNames = {
  0: i18n.global.t('cash'),
  1: i18n.global.t('cashDebit'),
  2: i18n.global.t('card'),
  3: 'XPR',
  4: i18n.global.t('contactMe'),
  5: i18n.global.t('insurance'),
  6: i18n.global.t('reward')
};

export const paymentTypesList = [
  {
    id: PaymentTypes.CASH,
    name: i18n.global.t('cash')
  }
  // {
  //   id: PaymentTypes.ONLINE,
  //   name: i18n.global.t('card')
  // }
];

export const providersLogos = {
  bank: bankIco,
  paypal: paypalIco,
  payoneer: payoneerIco,
  stripe: stripeIco
};

export const InputTypes = {
  TEXT: 1,
  NUMBER: 2,
  DATE: 3,
  TIME: 4,
  EMAIL: 5,
  PHONE: 6,
  URL: 7,
  FILE: 8,
  LOCATION: 9,
  SELECT: 10,
  MULTI_SELECT: 11,
  RADIO: 12,
  CHECKBOX: 13,
  IMAGE: 14,
  VIDEO: 15,
  AUDIO: 16,
  DOCUMENT: 17,
  PASSWORD: 18,
  TEXTAREA: 19,
  HTML: 20,
  MARKDOWN: 21,
  COLOR: 22,
  RANGE: 23,
  SLIDER: 24,
  STAR_RATING: 25,
  EMOJI: 26,
  AUTOCOMPLETE: 27,
  DATETIME: 28,
  TIME_COUNTER: 29
};

export const inputHintTypes = {
  PICKUP_ORDER: 1
};

export const InvertedInputHintTypes = invert(inputHintTypes);

export const permissionDialogInfo = [
  {
    id: 1,
    name: i18n.global.t('license'),
    generalManager: false,
    support: false,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 2,
    name: i18n.global.t('users'),
    generalManager: true,
    support: false,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 4,
    name: i18n.global.t('navigationCatalog'),
    generalManager: true,
    support: true,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 5,
    name: i18n.global.t('navigationFaq'),
    generalManager: true,
    support: true,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 6,
    name: i18n.global.t('navigationTemplates'),
    generalManager: true,
    support: true,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 7,
    name: i18n.global.t('navigationFeedbacks'),
    generalManager: true,
    support: false,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 8,
    name: i18n.global.t('navigationReviews'),
    generalManager: true,
    support: false,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 9,
    name: i18n.global.t('crmChat'),
    generalManager: true,
    support: true,
    orders: false,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 90,
    name: i18n.global.t('pointOfSale'),
    generalManager: true,
    support: true,
    orders: true,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 10,
    name: i18n.global.t('orders'),
    generalManager: true,
    support: true,
    orders: true,
    driver: true,
    processing: true,
    accountant: true
  },
  {
    id: 11,
    name: i18n.global.t('bookings'),
    generalManager: true,
    support: true,
    orders: true,
    driver: false,
    processing: false,
    accountant: false
  },
  {
    id: 12,
    name: i18n.global.t('accounting'),
    generalManager: false,
    support: false,
    orders: false,
    driver: false,
    processing: false,
    accountant: true
  },
  {
    id: 13,
    name: i18n.global.t('notifications'),
    generalManager: false,
    support: true,
    orders: true,
    driver: false,
    processing: true,
    accountant: false
  },
  {
    id: 999, // static for select role from table
    name: '',
    generalManager: true,
    support: true,
    orders: true,
    driver: true,
    processing: true,
    accountant: true
  }
];

export const StatusTypes = {
  order: 1,
  preDelivery: 4,
  delivery: 5,
  dispute: 7
};
export const statusConfigs = {
  [StatusTypes.order]: {
    id: StatusTypes.order,
    type: 'orderStatus',
    className: 'status-order-color',
    availableStatuses: [1, 2, 3, 4, 5, 6, 8, 9],
    titleKey: 'orderStatuses'
  },
  [StatusTypes.preDelivery]: {
    id: StatusTypes.preDelivery,
    type: 'preDeliveryStatus',
    className: 'status-pre-delivery-color',
    availableStatuses: [1, 2, 8],
    titleKey: 'preDeliveryStatuses'
  },
  [StatusTypes.dispute]: {
    id: StatusTypes.dispute,
    type: 'disputeStatus',
    className: 'status-dispute-color',
    availableStatuses: 'all',
    titleKey: 'disputeStatus'
  },
  [StatusTypes.delivery]: {
    id: StatusTypes.delivery,
    type: 'deliveryStatus',
    className: 'status-delivery-color',
    availableStatuses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    titleKey: 'deliveryStatus'
  }
};

// eslint-disable-next-line no-unused-vars
export const statusTypes = {
  OrderAppealStatus: 13,
  OrderModerationStatus: 11,
  CustomerNotificationResponse: 10,
  DeliveryShipmentStatus: 9,
  DriverNotificationStatus: 8,
  DisputeStatus: 7,
  PostDeliveryStatus: 6,
  DeliveryStatus: 5,
  PreDeliveryStatus: 4,
  DeliveryModeStatus: 3,
  OrderPaymentStatus: 2,
  OrderStatus: 1
};

export const invertedStatusTypes = invert(statusTypes);

export const statuses = {};

export const numerableIndex = {
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣',
  10: '🔟',
  11: '1️⃣1️⃣',
  12: '1️⃣2️⃣',
  13: '1️⃣3️⃣',
  14: '1️⃣4️⃣',
  15: '1️⃣5️⃣',
  16: '1️⃣6️⃣',
  17: '1️⃣7️⃣',
  18: '1️⃣8️⃣',
  19: '1️⃣9️⃣',
  20: '2️⃣0️⃣',
  21: '2️⃣1️⃣',
  22: '2️⃣2️⃣',
  23: '2️⃣3️⃣',
  24: '2️⃣4️⃣',
  25: '2️⃣5️⃣',
  26: '2️⃣6️⃣',
  27: '2️⃣7️⃣',
  28: '2️⃣8️⃣',
  29: '2️⃣9️⃣',
  30: '3️⃣0️⃣',
  31: '3️⃣1️⃣',
  32: '3️⃣2️⃣',
  33: '3️⃣3️⃣',
  34: '3️⃣4️⃣',
  35: '3️⃣5️⃣',
  36: '3️⃣6️⃣',
  37: '3️⃣7️⃣',
  38: '3️⃣8️⃣',
  39: '3️⃣9️⃣',
  40: '4️⃣0️⃣',
  41: '4️⃣1️⃣',
  42: '4️⃣2️⃣',
  43: '4️⃣3️⃣',
  44: '4️⃣4️⃣',
  45: '4️⃣5️⃣',
  46: '4️⃣6️⃣',
  47: '4️⃣7️⃣',
  48: '4️⃣8️⃣',
  49: '4️⃣9️⃣',
  50: '5️⃣0️⃣'
};
