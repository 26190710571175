import api from '@/api/profile';

import {useCache, useMutation, useQuery} from '@/helpers/xQuery';

// import errorTransformer from '@/helpers/errorTransformer';
import {computed, ref} from 'vue';

const {fetchData: getProfile, data: profile} = useQuery('profile', api.fetch);

export function useUser() {
  const {sendRequest, loading: updateProfileLoading, error: updateProfileError} = useMutation();
  const {updateCache} = useCache();

  const updateProfile = async data => {
    const result = await sendRequest(api.update, data);
    updateCache('replaceFully', profile, result);
    profile.value = result;
  };

  const checkUniqNicknameResult = ref(null);
  const checkUniqNicknameLoading = ref(false);
  const checkUniqNicknameError = ref(null);
  const checkUniqNickname = async payload => {
    try {
      checkUniqNicknameLoading.value = true;
      checkUniqNicknameError.value = null;
      checkUniqNicknameResult.value = null;
      const result = await api.checkUniqNickname(payload);
      checkUniqNicknameResult.value = result;
      return result;
    } catch (err) {
      checkUniqNicknameError.value = err.response;
      throw new Error('Chant check unique nickname');
    } finally {
      checkUniqNicknameLoading.value = false;
    }
  };

  const userByProperty = ref(null);
  const userByPropertyError = ref(null);
  const userByPropertyLoading = ref(false);

  const fetchUserIdByProperty = async payload => {
    try {
      userByPropertyError.value = null;
      userByPropertyLoading.value = true;
      const result = await api.fetchUserByProperty(payload);
      userByProperty.value = result;
    } catch (err) {
      userByPropertyError.value = err.response.data;
      throw new Error('Error with get user_id by property');
    } finally {
      userByPropertyLoading.value = false;
    }
  };

  const updatePhoneLoading = ref(false);
  const updatePhoneError = ref(null);
  const updatePhone = async params => {
    try {
      updatePhoneError.value = null;
      updatePhoneLoading.value = true;
      await api.updatePhone(params);
    } catch (err) {
      updatePhoneError.value = err.response;
      throw new Error('Cant update phone number ' + JSON.stringify(err.response.data?.data));
    } finally {
      updatePhoneLoading.value = false;
    }
  };

  return {
    updatePhone,
    updatePhoneError,
    updatePhoneLoading,
    checkUniqNickname,
    checkUniqNicknameError,
    checkUniqNicknameLoading,
    checkUniqNicknameResult,
    userByProperty,
    userByPropertyError,
    userByPropertyLoading,
    fetchUserIdByProperty,
    getProfile,
    profile,
    updateProfile,
    updateProfileLoading,
    updateProfileError
    // updateProfileError: computed(() => errorTransformer(updateProfileError.value?.response.data))
  };
}

export function useProfiles() {
  const profiles = ref([]);
  const profilesLoading = ref(false);
  const fetchProfiles = async params => {
    try {
      profilesLoading.value = true;
      profiles.value = await api.fetchProfiles(params);
    } finally {
      profilesLoading.value = false;
    }
  };

  const mappedProfiles = computed(() => {
    return profiles.value?.reduce((acc, item) => {
      acc[item.userId] = {
        ...item,
        fullName: item.nickname
      };
      return acc;
    }, {});
  });
  return {
    profilesLoading,
    fetchProfiles,
    profiles,
    mappedProfiles
  };
}
