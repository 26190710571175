import useEventsBus from '@/helpers/eventBus';
import {clearTokens, getRefreshToken, saveTokensToStorage} from '@/helpers/tokens';

import {toSnakeCase} from '@/helpers';
import i18n from '@/i18n';
import router from '@/router';
import axios from 'axios';

const {emitBus} = useEventsBus();

const camelcaseObjectDeep = require('camelcase-object-deep');

// eslint-disable-next-line
const locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1') || process.env.VUE_APP_I18N_LOCALE;
const APIGW_URL = process.env.VUE_APP_APIGW_URL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};
export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('accessToken'))}`,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

const handleRefresh = originalRequest => {
  if (!originalRequest.retry && router.currentRoute.value.name !== 'Login') {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({resolve, reject});
      })
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch(error => error);
    }
    originalRequest.retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      const localRefreshToken = getRefreshToken();
      axios({
        method: 'post',
        url: `${APIGW_URL}/auth/refresh-token`,
        data: {
          refresh_token: localRefreshToken
        }
      })
        .then(response => {
          const {accessToken, refreshToken} = camelcaseObjectDeep(response.data.data);
          saveTokensToStorage({accessToken, refreshToken});
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          $http.defaults.headers.Authorization = `Bearer ${accessToken}`;
          processQueue(null, accessToken);
          resolve(axios(originalRequest));
        })
        .catch(error => {
          clearTokens();
          router.push({ name: 'Registration', params: { locale: locale } });
          processQueue(error, null);
          reject(error);
          setTimeout(() => {
            location.reload();
          }, 300);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  } else {
    clearTokens();
    router.push({ name: 'Registration', params: { locale: locale } });
  }
};

$http.interceptors.request.use(config => {
  const isAuthenticated = !!localStorage.getItem('accessToken');
  if (isAuthenticated) {
    config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('accessToken'))}`;
  } else {
    delete config.headers.Authorization;
  }
  if (config.method !== 'get' && config.data && !Object.hasOwn(config.data, 'transformSyntax')) {
    config.data = toSnakeCase(config.data, Array.isArray(config.data) ? 'array' : 'object');
    return config;
  }
  if (config.data && !Object.hasOwn(config.data, 'transformSyntax')) {
    config.data = toSnakeCase(config.data);
  }
  return config;
});
$http.interceptors.response.use(
  response => {
    return camelcaseObjectDeep(response);
  },
  e => {
    const error = camelcaseObjectDeep(e);
    const originalRequest = error.config;
    console.log('interceptors.request.use error', error);
    switch (error?.response?.status) {
      case 500: {
        break;
      }
      case 401: {
        handleRefresh(originalRequest);
        break;
      }
      case 403: {
        const errorType = error.response.data.data.type;
        if (errorType === 'subscription_not_despaxo') {
          emitBus('packageUpgradeDialog', {
            show: true,
            text: i18n.global.t('upgradePackageText1'),
            canClose: false
          });
        }
        if (errorType === 'subscription_limit_exceeded') {
          emitBus('packageUpgradeDialog', {
            show: true,
            text: i18n.global.t('upgradePackageText2'),
            canClose: true
          });
        }
        break;
      }
      case 404: {
        break;
      }
      case 423: {
        break;
      }
      default: {
        //
      }
    }
    throw error;
  }
);

export default $http;

export const $httpAccounting = axios.create({
  baseURL: process.env.VUE_APP_ACCOUNTING_API,
  headers: {
    'secret-key': process.env.VUE_APP_ACCOUNTING_SECRET_KEY,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

$httpAccounting.interceptors.request.use(config => {
  if (config.method !== 'get' && config.data) {
    config.data = toSnakeCase(config.data, Array.isArray(config.data) ? 'array' : 'object');
    return config;
  }
  return config;
});
$httpAccounting.interceptors.response.use(
  response => {
    return camelcaseObjectDeep(response);
  },
  e => {
    const error = camelcaseObjectDeep(e);
    switch (error?.response?.status) {
      case 500: {
        break;
      }
      case 401: {
        break;
      }
      case 403: {
        break;
      }
      case 404: {
        break;
      }
      case 423: {
        break;
      }
      default: {
        //
      }
    }
    throw error;
  }
);
