import { createRouter, createWebHistory } from 'vue-router';
import i18n from '@/i18n';
// eslint-disable-next-line
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1') || process.env.VUE_APP_I18N_LOCALE;
const routes = [
  {
    path: '/:locale(.{2})?/',
    name: 'index',
    redirect: {
        name: 'Registration'
    }
  },
  {
    path: '/:locale(.{2})?/auth',
    name: 'Auth',
    component: () => import('@/modules/auth/index.vue'),
    meta: {
      pageTitle: i18n.global.t('loginTitle')
    },
    children: [
      {
        path: 'sign-up',
        name: 'Registration',
        component: () => import('@/modules/auth/SingUp.vue'),
        meta: {
          pageTitle: i18n.global.t('signUp')
        }
      },
      {
        path: 'sign-in',
        name: 'Login',
        component: () => import('@/modules/auth/SignIn.vue'),
        meta: {
          pageTitle: i18n.global.t('signIn')
        }
      },
      {
        path: 'invite',
        name: 'Invite',
        component: () => import('@/modules/auth/Invite.vue'),
        meta: {
          pageTitle: i18n.global.t('invite')
        }
      }
    ]
  },
  {
    path: '/:locale(.{2})?/open',
    name: 'AuthByLink',
    component: () => import('@/modules/auth/AuthByLink.vue'),
    meta: {
      pageTitle: i18n.global.t('loginTitle')
    }
  },
  {
    path: '/:locale(.{2})?/demo-mode',
    name: 'DemoModeRedirect',
    component: () => import('@/modules/system/DemoModeRedirect.vue'),
    meta: {
      pageTitle: i18n.global.t('demoMode')
    }
  },
  {
    path: '/:locale(.{2})?/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/modules/text-pages/privacy-policy/index.vue'),
    meta: {
      pageTitle: i18n.global.t('privacyPolicy')
    }
  },
  {
    path: '/:locale(.{2})?/terms-of-use',
    name: 'TermsOfUse',
    component: () => import('@/modules/text-pages/terms-of-use/index.vue'),
    meta: {
      pageTitle: i18n.global.t('termsAndConditions')
    }
  },
  {
    path: '/:locale(.{2})?/data-processing-agreement',
    name: 'DataProcessingAgreement',
    component: () => import('@/modules/text-pages/agreement/index.vue'),
    meta: {
      pageTitle: i18n.global.t('dataProcessingAgreement')
    }
  }
];
const router = createRouter({
  base: (locale.trim().length && locale !== '/') ? '/' + locale : process.env.VUE_APP_I18N_LOCALE,
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes
});

const availableLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE;
router.beforeEach((to, from, next) => {
  if (availableLocales.includes(to.params.locale)) {
    next();
  } else {
    next({
      name: to.name,
      params: { locale: process.env.VUE_APP_I18N_LOCALE },
      query: to.query
    });
  }
});


export default router;
