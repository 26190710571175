<template>
  <button
    v-if="!href"
    class="main-button"
    :style="customStyles"
    :disabled="disabled"
    :data-block="block"
    :data-progress="loading ? 'true' : 'false'"
    :type="type"
    :data-color="color"
    :data-rounded="rounded"
    :data-outline="outline"
    :data-fab="fab"
    :data-text="text"
    :data-size="size"
    @click.prevent.stop="changeEvent"
  >
    <span class="main-button__label">
      <slot></slot>
    </span>
    <span class="main-button__loader">
      <loader :color="loaderColor" :size="size" />
    </span>
  </button>
  <a
    v-else
    :href="href"
    :style="customStyles"
    target="_blank"
    :data-color="color"
    :data-rounded="rounded"
    :data-outline="outline"
    :data-block="block"
    :data-fab="fab"
    :data-text="text"
    :data-size="size"
    :class="{'disabled': disabled}"
    class="main-button"
  >
    <span class="main-button__label">
      <slot></slot>
    </span>
  </a>
</template>
<script>
import {toRefs} from 'vue';
import {useRouter} from 'vue-router';

import Loader from './Loader.vue';

export default {
  name: 'BaseButton',
  props: {
    block: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default'
    },
    customStyles: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    loaderColor: {
      type: String,
      default: 'white'
    },
    loading: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    text: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object]
    },
    type: {
      type: String,
      default: 'submit'
    }
  },
  emits: ['on-click'],
  components: {
    Loader
  },
  setup(props, {emit}) {
    const router = useRouter();
    const {to} = toRefs(props);
    const changeEvent = () => {
      if (to.value) {
        router.push(to.value);
        return;
      }
      emit('on-click');
    };

    return {
      changeEvent
    };
  }
};
</script>
<style lang="scss">
.main-button {
  position: relative;
  border: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  min-width: 100px;
  font-size: 16px;
  border-radius: 3px;
  transition: background-color $trans;
  color: $white;
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  // for tag <a>
  text-decoration: none;
  @include respondTo(2000px) {
    font-size: 14px;
  }
  @include respondTo(580px) {
    height: 45px;
  }

  &[data-block='true'] {
    width: 100%;
  }

  &[data-color='default'] {
    background-color: $secondary;
    transition: background-color $trans;

    &:hover {
      background-color: $secondary-light;
    }
  }

  &[data-color='red'] {
    background-color: $red;
    transition: background-color $trans;

    &:hover {
      background-color: $red-hover;
    }
  }
  &[data-color='green'] {
    background-color: $avocado;
    transition: background-color $trans;

    &:hover {
      background-color: $avocado-hover;
    }
  }

  &[data-color='orange'] {
    background-color: $orange;
    transition: background-color $trans;

    &:hover {
      background-color: $orange-hover;
    }
  }

  &[data-color='silver'] {
    background-color: $silver-1;
    transition: background-color $trans;

    &:hover {
      background-color: $silver;
    }
  }

  &[data-color='transparent'] {
    background-color: transparent;
    transition: background-color $trans;
    color: $silver-1;

    &:hover {
      background-color: $silver-3;
    }
  }

  &[data-size='normal'] {
    padding: 10px 25px;
    height: 44px;
    @include respondTo(580px) {
      padding: 7px 20px;
      height: 40px;
    }
  }

  &[data-size='small'] {
    padding: 0 12px;
    height: 32px;
    font-size: 14px;
  }

  &[data-size='extra-small'] {
    padding: 0 10px;
    height: 28px;

    span {
      font-size: 12px;
    }
    .main-button__loader {
      top: 3px;
    }
  }

  &[data-fab='true'] {
    &[data-size='small'] {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      padding: 6px;
    }
    &[data-size='extra-small'] {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      padding: 4px;
    }

    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 0;
    }
  }

  &[data-size='large'] {
    padding: 0 20px;
    height: 54px;
    font-size: 16px;
    border-radius: 7px;
    .loader {
      width: 37px;
      height: 37px;
    }
    @include respondTo(600px) {
      height: 44px;
      padding: 0 15px;
    }
  }

  &[data-text='true'] {
    background: transparent;
    color: $primary;
    font-weight: 500;
    text-shadow: none;

    &:hover {
      background: $primary-light-2;
      color: $primary;
    }
  }

  &[data-rounded='true'] {
    border-radius: 10em;
  }

  &[data-outline='true'] {
    border: 1px solid $secondary;
    background: $white;
    color: $secondary;
    transition: background 0.3s ease, color 0.3s ease, border-color $trans;

    &:hover {
      background: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }

  &[data-progress='true'] {
    pointer-events: none;

    .main-button__label {
      opacity: 0;
    }

    .main-button__loader {
      display: flex;
      justify-content: center;
    }
  }

  &:disabled {
    pointer-events: none;
    background: $silver-1;
    opacity: 0.5;
  }
  &.disabled {
    pointer-events: none;
    background: $silver-1;
    opacity: 0.5;
  }

  &:hover {
    background-color: $primary;
  }

  &__label {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    svg {
      margin-right: 7px;
    }

    @include respondTo(1800px) {
      font-size: 14px;
    }
  }

  &__loader {
    display: none;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 25px;
    text-align: center;
    font-size: 0;
    @include respondTo(580px) {
      top: 7px;
    }
  }

  &__loader-inner {
    display: inline-block;
    height: 3em;
    width: 3em;
    font-size: 8px;
    text-indent: -9999em;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
