import $http from '@/utils/https';
import axios from 'axios';

const camelcaseObjectDeep = require('camelcase-object-deep');
const APIGW_URL = process.env.VUE_APP_APIGW_URL;
export default {
  async authByEmail(data) {
    const {template, ...other} = data;
    const response = await $http.post(
      `${APIGW_URL}/auth/v4BPx8kxPUM5utBksHOg?is_ventrix=true&template=${template}`,
      other
    );
    return response.data.data;
  },
  async refreshToken(data) {
    const response = await $http.post(`${APIGW_URL}/auth/refresh-token`, data);
    return response.data.data;
  },
  async authByMagicId({id}) {
    const response = await axios.get(`${APIGW_URL}/auth/v4BPx8kxPUM5utBksHOg?magic-id=${id}`);
    return camelcaseObjectDeep(response);
  },
  async confirmEmailOtp(payload) {
    const response = await $http.post(`${APIGW_URL}/auth/user/email-otp`, payload);
    return response.data.data;
  },
  async updateDriverInfo(payload) {
    const response = await $http.post(`${APIGW_URL}/chatbots/driver-info-data`, payload);
    return response.data.data;
  },
  logout(payload) {
    return $http.post(`${APIGW_URL}/auth/user/logout`, payload);
  },
  removeDemoAccount(payload) {
    return $http.delete(`${APIGW_URL}/chatbots/demo/delete?token=${payload.token}`);
  },
  async getTokenForDemo({fingerprint}) {
    const response = await $http.get(`${APIGW_URL}/chatbots/open/demo/token?fingerprint=${fingerprint}`);
    return response.data.data;
  },
  async storeToken(data) {
    return await $http.post(`${APIGW_URL}/auth/token-store`, data);
  },
  async testRequest() {
    const accessToken =
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjI3MywicGhvbmUiOiIrMzgwOTg3NjU0MzIxIiwicm9sZSI6IlVTRVIiLCJhdCI6WzQwMV0sImV4cCI6MTcwNjE4OTQ3NywidG9rZW5fdHlwZSI6ImFjY2VzcyIsImRldmljZSI6IjczNjE3OTQ4LTZmNmMtNjEyMC00MjZjLTZmNjcyMDc3NzAwMCIsImRldmljZV90eXBlIjoid2ViIn0.XPfQEGdOpUfnMhFN943BGV7iA2FcQRrMvk3FhkFn75s';
    return await axios({
      method: 'GET',
      url: `https://apigw.stage.virtuo.chat/user/ping`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
};
