import {snakeCase} from 'lodash';

export function generateQueryParams(params, firstSymbol='?') {
  if (!params || typeof params !== 'object') {
    return '';
  }

  const queryParams = Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map(item => {
            return `${encodeURIComponent(snakeCase(key))}=${encodeURIComponent(item)}`;
          })
          .join('&');
      } else {
        return `${encodeURIComponent(snakeCase(key))}=${encodeURIComponent(params[key])}`;
      }
    })
    .join('&');

  return queryParams ? `${firstSymbol}${queryParams}` : '';
}
