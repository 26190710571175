import {ref} from 'vue';

const actionTypes = {
  add: 'add',
  addItemData: 'addItemData',
  replaceItem: 'replaceItem',
  replaceFully: 'replaceFully',
  replaceDataItem: 'replaceDataItem',
  remove: 'remove',
  removeItemData: 'removeItemData'
};

/* eslint-disable */
// const defaultOption = {
//   dynamicQueryKey: false,
//   checkCache: true
// };

export const useQuery = (queryKey, request, inputOptions = {}) => {
  const storage = new Map();

  // const options = Object.assign(defaultOption, inputOptions);

  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const fetchData = (params = {}) => {
    if (typeof params !== 'object' || params === null) {
      throw new Error('Input data type must be an object');
    }
    const key = JSON.stringify([queryKey, ...Object.values(params)]);
    const {forceUpdate = false, ...other} = params;

    if (storage.has(key)) {
      data.value = storage.get(key);
      if (!forceUpdate) {
        return;
      }
    }

    loading.value = true;
    return request(other)
      .then(resp => {
        storage.set(key, resp);
        data.value = resp;
        return resp;
      })
      .catch(err => {
        error.value = err;
        throw new Error(`Error code ${err.response.status} - ${JSON.stringify(err.response.data)}`);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    data,
    error,
    loading,
    fetchData
  };
};

export const useMutation = () => {
  const loading = ref(false);
  const error = ref(null);

  const sendRequest = (request, data) => {
    loading.value = true;
    return request(data)
      .then(resp => {
        return resp;
      })
      .catch(err => {
        error.value = err;
        throw new Error(`Error code ${err.response.status} - ${JSON.stringify(err.response.data)}`);
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return {
    sendRequest,
    loading,
    error
  };
};

export const useCache = () => {
  const updateCache = (action, data, newData, key = 'id') => {
    switch (action) {
      case actionTypes.add: {
        if (data.value) {
          if (data.value.some(e => e[key] === newData[key])) {
            // case for resend invite in managers entity
            const index = data.value.findIndex(e => e[key] === newData[key]);
            data.value.splice(index, 1, newData);
          } else {
            data.value.push(newData);
          }
        } else {
          data.value = [newData];
        }
        break;
      }
      case actionTypes.addItemData: {
        if (data.value) {
          data.value.data.push(newData);
        } else {
          data.value.data = [newData];
        }
        break;
      }
      case actionTypes.replaceItem: {
        const index = data.value.findIndex(e => e[key] === newData[key]);
        data.value.splice(index, 1, newData);
        break;
      }
      case actionTypes.replaceDataItem: {
        const index = data.value.data.findIndex(e => e[key] === newData[key]);
        data.value.data.splice(index, 1, newData);
        break;
      }
      case actionTypes.replaceFully: {
        data.value = newData;
        break;
      }
      case actionTypes.removeItemData: {
        data.value.data = data.value.data.filter(e => e[key] !== newData[key]);
        break;
      }
      default: {
        data.value = data.value.filter(e => e[key] !== newData[key]);
        break;
      }
    }
  };
  return {
    updateCache
  };
};
