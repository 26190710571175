import {useQueryClient} from 'vue-query';

export function queryCache(ids) {
  const queryClient = useQueryClient();
  // working with list type data
  const replaceItemInCache = data => {
    queryClient.setQueryData(ids, oldData => {
      return oldData?.map(e => (e.id === data.id ? data : e));
    });
  };

  const removeItemFromCache = id => {
    queryClient.setQueryData(ids, oldData => {
      return oldData.filter(e => e.id !== id);
    });
  };

  const addItemToCache = data => {
    queryClient.setQueryData(ids, oldData => {
      const existItem = oldData?.find(e => e.id === data.id);
      if (existItem) {
        return oldData;
      }
      return oldData ? [...oldData, data] : [data];
    });
  };

  const removeAllCache = () => {
    queryClient.setQueryData(ids, () => {
      return [];
    });
  };

  //working with object type data
  const replaceCache = data => {
    queryClient.setQueryData(ids, oldData => {
      return oldData ? Object.assign(oldData, data) : data;
    });
  };

  return {
    replaceItemInCache,
    removeItemFromCache,
    addItemToCache,
    removeAllCache,
    replaceCache
  };
}
