import api from '@/api/manager';

import {queryCache} from '@/helpers/queryCache';

import {ref} from 'vue';
import {useMutation, useQuery} from 'vue-query';

const ENTITY_NAME = 'managers';

export function useManagers(id = null) {
  const isDemoMode = localStorage.getItem('demo-mode') === 'true';
  const ids = [ENTITY_NAME];

  const {addItemToCache, replaceItemInCache, removeItemFromCache} = queryCache(ids);

  const queryParams = ref({id});

  const updateQueryParams = query => {
    queryParams.value = query;
  };

  const managers = useQuery(ids, () => api.fetch(), {
    enabled: false,
    retry: 0
  });

  const manager = useQuery([ENTITY_NAME, id], () => api.fetchById(queryParams.value), {
    enabled: !!id && !isDemoMode,
    retry: 0,
    refetchOnWindowFocus: false
  });

  const createManager = useMutation(params => api.create(params), {
    onSuccess(response) {
      addItemToCache(response);
    }
  });

  const updateManager = useMutation(params => api.update(params), {
    onSuccess(response) {
      replaceItemInCache(response);
    }
  });

  const removeManager = useMutation(params => api.remove(params), {
    onSuccess(id) {
      removeItemFromCache(id);
    }
  });

  return {
    updateManagerQueryParams: updateQueryParams,
    managers,
    manager,
    createManager,
    removeManager,
    updateManager
  };
}
