import api from '@/api/session';

import {useProfiles} from '@/composable/useUsers';

import i18n from '@/i18n';
import {computed, ref} from 'vue';

const myOwnedSession = {
  isActiveWorkspace: false,
  isDefault: true,
  id: '',
  title: i18n.global.t('myWorkspace')
};

const {fetchProfiles, mappedProfiles} = useProfiles();

const sessions = ref([]);

export function useSession() {
  const sessionLoading = ref(false);
  const fetchSessions = async () => {
    sessionLoading.value = true;
    try {
      const result = await api.fetch();
      const ownerIds = result.map(item => item.ownerId);

      await fetchProfiles({usersId: ownerIds});

      const formattedList = result.map(e => {
        const {firstName, lastName, avatarLink, phone, ventrixPackage} = mappedProfiles.value[e.ownerId];
        return {
          ...e,
          isDefault: false,
          firstName,
          lastName,
          avatarLink,
          phone,
          ownerPackageId: ventrixPackage || 1
        };
      });
      sessions.value = [myOwnedSession, ...formattedList];
    } finally {
      sessionLoading.value = false;
    }
  };

  const updateSession = async params => {
    try {
      await api.update(params);
      replaceCacheItem(params);
    } catch (err) {
      console.log(err);
    }
  };

  const replaceCacheItem = params => {
    const index = sessions.value.findIndex(e => e.id === params.workspaceId);
    const currentItem = sessions.value.find(e => e.id === params.workspaceId);
    currentItem.isActiveWorkspace = true;
    sessions.value.splice(index, 1, currentItem);
  };

  const currentSession = computed(() => {
    return sessions.value.find(e => e.isActiveWorkspace);
  });

  return {
    sessionLoading,
    fetchSessions,
    sessions,
    currentSession,
    updateSession
  };
}
