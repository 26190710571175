import {generateQueryParams} from '@/helpers/url';

import $http from '@/utils/https';

const API_URL = `${process.env.VUE_APP_APIGW_URL}`;

export default {
  async fetch() {
    const response = await $http(`${API_URL}/user/user/my_profile`);
    return response.data.data;
  },
  async update(payload) {
    const response = await $http.patch(`${API_URL}/user/user/my_profile`, payload);
    return response.data.data;
  },
  async fetchAccountType() {
    const response = await $http(`${API_URL}/auth/user/account-type`);
    return response.data.data;
  },
  async createAccountType(payload) {
    const response = await $http.post(`${API_URL}/auth/user/account-type`, payload);
    return response.data.data;
  },
  async fetchUserInfo() {
    const response = await $http(`${API_URL}/auth/user/info`);
    return response.data.data;
  },
  async fetchUserByProperty(params) {
    const response = await $http(`${API_URL}/user/info/get-user-id${generateQueryParams(params)}`);
    return response.data.data;
  },
  async fetchProfiles(params) {
    const response = await $http(`${API_URL}/user/user/profiles${generateQueryParams(params)}`);
    return response.data.data;
  },
  async checkUniqNickname(payload) {
    const response = await $http.post(`${API_URL}/user/user/check-unique-nickname`, payload);
    return response.data.data;
  },
  async updatePhone(data) {
    const response = await $http.post(`${API_URL}/auth/phone/update-phone`, data);
    return response.data.data;
  }
};
