import {chatNamesForBridge, roles} from '@/helpers/variables';

import i18n from '@/i18n';

const snakeKeysObjectDeep = require('snakecase-keys');

export function toSnakeCase(data, type = 'object') {
  let payload = type === 'object' ? {} : [];
  Object.entries(data).forEach(e => {
    // eslint-disable-next-line no-unused-vars
    const [key, value] = e;
    if (type === 'object') {
      payload = snakeKeysObjectDeep(data);
    } else {
      payload.push(snakeKeysObjectDeep(value));
    }
  });
  return payload;
}

export const isMobile = {
  Android: function () {
    return !!navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return !!navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Windows: function () {
    return !!navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows();
  }
};

export const isIOSDevice = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);

export const configDatePicker = (enableTime = true, inline = true) => {
  return {
    altFormat: 'M j H:i, Y',
    altInput: true,
    dateFormat: 'Y-m-d H:i',
    enableTime: enableTime,
    inline: inline,
    minDate: 'today'
  };
};

export const deviceForAuth = {
  id: '73617948-6f6c-6120-426c-6f6720777000',
  type: 'web'
};

export const LOCALES = {
  en: 'English',
  ja: '日本語',
  ru: 'Русский',
  es: 'Español',
  'zh-hans': '简体中文',
  pt: 'Português',
  fr: 'Français',
  de: 'Deutsch'
};

export const dataForCreateBridgeTGRoom = {
  initial_state: [
    {
      type: 'm.room.guest_access',
      state_key: '',
      content: {
        guest_access: 'can_join'
      }
    }
  ],
  name: chatNamesForBridge.telegram,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_TELEGRAM_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
};

export const dataForCreateBridgeWARoom = {
  initial_state: [
    {
      type: 'm.room.guest_access',
      state_key: '',
      content: {
        guest_access: 'can_join'
      }
    }
  ],
  name: chatNamesForBridge.whatsapp,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_WHATSAPP_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
};

export function copyToClipboard(data) {
  navigator.clipboard.writeText(data).catch(function (err) {
    console.error('Copy error: ', err);
  });
}

export function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charsetLength = charset.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    result += charset[randomIndex];
  }

  return result;
}

export function calculateAspectRatioFit({width, height, maxWidth, maxHeight}) {
  const ratio = Math.min(maxWidth / width, maxHeight / height);
  return {
    width: width * ratio,
    height: height * ratio
  };
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0)
    return {
      size: 0,
      ext: 'Bytes'
    };

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  const size = Math.round(100 * (bytes / Math.pow(1024, i))) / 100;
  const ext = sizes[i];
  return {
    size,
    ext
  };
}

export const ManagerEntityTypes = {
  users: 'users',
  admins: 'admins'
};

export const ROLES = [
  {
    id: roles.ADMIN.toString(),
    name: i18n.global.t('adminLabel')
  },
  {
    id: roles.SUPPORT.toString(),
    name: i18n.global.t('support')
  },
  {
    id: roles.ORDERS.toString(),
    name: i18n.global.t('orders')
  },
  {
    id: roles.GENERAL_MANAGER.toString(),
    name: i18n.global.t('generalManager')
  },
  {
    id: roles.SALES_REPRESENTATIVE.toString(),
    name: i18n.global.t('salesRepresentative')
  },
  {
    id: roles.DRIVER.toString(),
    name: i18n.global.t('driver')
  },
  {
    id: roles.PROCESSING.toString(),
    name: i18n.global.t('processing')
  },
  {
    id: roles.ACCOUNTANT.toString(),
    name: i18n.global.t('accountant')
  },
  {
    id: roles.VENTRIX_SUPPORT.toString(),
    name: i18n.global.t('ventrixSupport')
  },
  {
    id: roles.OWNER.toString(),
    name: i18n.global.t('owner')
  }
  // {
  //   id: roles.BRANCH_REPRESENTATIVE.toString(),
  //   name: i18n.global.t('connectionManager'),
  // }
];

export const plugImage = require('@/assets/images/no_image_available.jpg');

export function trimObject(obj, count) {
  const trimmedObj = {};
  for (let key in obj) {
    if (parseInt(key) <= count) {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
}

export const AssetScheduleStatuses = {
  AVAILABLE: 0,
  BOOKED: 1,
  UNAVAILABLE: 2
};

export const configTree = {
  roots: [],
  openedIcon: {
    type: 'shape',
    stroke: 'black',
    strokeWidth: 3,
    viewBox: '0 0 24 24',
    draw: 'M 2 12 L 22 12'
  },
  closedIcon: {
    type: 'shape',
    stroke: 'black',
    strokeWidth: 3,
    viewBox: '0 0 24 24',
    draw: `M 12 2 L 12 22 M 2 12 L 22 12`
  }
};
