<template>
  <div class="wrapper">
    <div class="wrapper-top">
      <template v-if="hasAccessToken">
        <template v-if="profile">
          <RouterView />
        </template>
        <div v-else class="block-loader">
          <Loader size="large" />
        </div>
      </template>
      <template v-else>
        <RouterView />
      </template>
    </div>
    <div class="wrapper-bot"></div>
    <MetaPage></MetaPage>
    <teleport to="body">
      <transition name="popup">
        <PopupWrap
          v-if="packageUpgradeDialog?.show"
          :has-close-btn="packageUpgradeDialog?.canClose"
          :max-width="800"
          style="z-index: 55"
          :title="$t('upgradeYourPackage')"
          @close-dialog="closeUpgradePackageDialog"
        >
          <UpgradePackage />
        </PopupWrap>
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="popup">
        <PopupWrap
          :max-width="450"
          :title="$t('permissionsDenied')"
          v-if="isShowPermissionModal"
          @close-dialog="isShowPermissionModal = false"
        >
          <div class="permission-content" v-html="permissionsDialogText"></div>
        </PopupWrap>
      </transition>
    </teleport>
  </div>
  <DisableMobileVersion />
</template>
<script setup>
import MetaPage from '@/components/MetaPage';
import PopupWrap from '@/components/PopupWrap.vue';
import UpgradePackage from '@/components/UpgradePackage.vue';
import Loader from '@/components/atoms/Loader.vue';
import DisableMobileVersion from '@/components/features/DisableMobileVersion.vue';

import {useLocalStorage} from '@/composable/useLocalStorage';
import {useManagers} from '@/composable/useManagers';
import {useSession} from '@/composable/useSession';
import {useUser} from '@/composable/useUsers';

import useEventsBus from '@/helpers/eventBus';
import {roles} from '@/helpers/variables';

import moment from 'moment';
import {computed, ref, watchEffect} from 'vue';
import {useQueryProvider} from 'vue-query';
import {useRoute} from "vue-router";
import {clearTokens} from "@/helpers/tokens";
import {useAuth} from "@/composable/auth";

const route = useRoute();

/* eslint-disable */
const locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1') || process.env.VUE_APP_I18N_LOCALE;
moment.locale(locale);
useQueryProvider();

const {profile, getProfile} = useUser();
const isShowPermissionModal = ref(false);
const permissionsDialogText = ref('');
const {bus, emitBus} = useEventsBus();

const {manager, updateManagerQueryParams} = useManagers(profile.value?.userId);
const {isDemoMode, hasAccessToken} = useLocalStorage();
const {setAuthStatus} = useAuth();

const {fetchSessions, sessions} = useSession();
if(route.path.includes('/auth/')){
  clearTokens();
  setAuthStatus(false);
}
if (localStorage.getItem('accessToken') && !route.path.includes('/auth/')) {
  fetchSessions();
  getProfile();
}

watchEffect(() => {
  if (profile.value && !manager.data.value) {
    if (isDemoMode) return;
    if (sessions.value.length > 0) {
      const currentSession = sessions.value.find(e => e.isActiveWorkspace);
      if (currentSession) {
        emitBus('role', currentSession.role);
        updateManagerQueryParams({
          id: profile.value.userId
        });
        manager.refetch.value();
      } else {
        emitBus('role', roles.ADMIN);
      }
    } else {
      emitBus('role', roles.ADMIN);
    }
  }
});

const packageUpgradeDialog = computed(() => {
  const packageUpgradeDialog = bus.value.get('packageUpgradeDialog');
  if (!packageUpgradeDialog) return {};
  const [packageUpgrade] = packageUpgradeDialog;
  if (packageUpgrade) {
    return packageUpgrade;
  }
  return {};
});

const closeUpgradePackageDialog = () => {
  if (packageUpgradeDialog.value?.canClose) {
    emitBus('packageUpgradeDialog', {
      show: false
    });
  }
};
</script>
<style src="./assets/scss/main.scss" lang="scss"></style>
