import apiAuth from '@/api/auth';

import {clearTokens, getRefreshToken, saveTokensToStorage} from '@/helpers/tokens';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {computed, ref} from 'vue';
import {useMutation} from 'vue-query';
import {useRouter} from 'vue-router';

const device = {
  id: '73617948-6f6c-6120-426c-6f6720777000',
  type: 'web'
};

const isAuthenticated = ref(false);
export const useAuth = () => {
  const testRequest = async params => {
    const result = await apiAuth.testRequest(params);
    return result.data;
  };
  const router = useRouter();

  const sendEmail = useMutation(params =>
    apiAuth.authByEmail(
      {
        ...params,
        device
      },
      {cacheTime: 0}
    )
  );

  const confirmEmailOtp = useMutation(params =>
    apiAuth.confirmEmailOtp(
      {
        ...params,
        device
      },
      {cacheTime: 0}
    )
  );

  const setAuthStatus = status => {
    isAuthenticated.value = status;
  };

  const updateDriverInfoLoading = ref(false);
  const updateDriverInfoError = ref(false);
  const updateDriverInfo = async payload => {
    try {
      updateDriverInfoLoading.value = true;
      await apiAuth.updateDriverInfo(payload);
    } catch (err) {
      updateDriverInfoError.value = err.response;
    } finally {
      updateDriverInfoLoading.value = false;
    }
  };

  const authByMagicId = useMutation(params => apiAuth.authByMagicId(params), {
    async onSuccess(response) {
      const {token} = response.data.data
      saveTokensToStorage(token)
      setAuthStatus(true)
    },
    onError() {
      clearTokens();
    }
  });

  const logout = useMutation(
    async () =>
      await apiAuth.logout({
        refreshToken: getRefreshToken()
      }),
    {
      async onMutate() {
        await router.push({name: 'Login'});
        clearTokens();
        setAuthStatus(false);
        location.reload();
      }
    }
  );

  const refreshToken = useMutation(params => apiAuth.refreshToken(params));

  const generateFingerprint = async () => {
    const fpPromise = await FingerprintJS.load();
    const result = await fpPromise.get();
    return result.visitorId;
  };

  const storeToken = async data => {
    await apiAuth.storeToken(data);
  };

  setAuthStatus(!!localStorage.getItem('accessToken'));

  return {
    updateDriverInfo,
    refreshToken,
    confirmEmailOtp,
    logout: logout.mutate,
    isAuthenticated,
    sendEmail,
    authByMagicId,
    authByMagicIdErrors: computed(() => authByMagicId.error?.value?.response.data.data),
    setAuthStatus,
    generateFingerprint,
    storeToken,
    testRequest
  };
};
