{
  "terms": "Términos",
  "privacyPolicy": "Política de privacidad",
  "confirm": "Confirmar",
  "formEmail": "Correo electrónico",
  "validationField_required": "Rellene este campo",
  "validationField_maxLength": "La longitud máxima permitida es de {max}",
  "validationField_minLength": "Este campo debe tener al menos {min} caracteres",
  "validationField_maxValue": "El valor máximo es {max}",
  "validationField_email": "El valor no es una dirección de correo electrónico válida",
  "validationField_numeric": "El valor debe ser numérico",
  "validationField_url": "El valor no es una dirección URL válida",
  "validationField_alpha": "El valor no es alfabético",
  "validationField_alphaNum": "El valor debe ser alfanumérico",
  "validationError_exists": "Este valor para el campo {name} ya existe",
  "validationError_somethingWrong": "Algo salió mal con el campo {name}",
  "serverError_login_expired": "Este enlace ha expirado",
  "gotToSignIn": "Ir a iniciar sesión",
  "chooseOptions": "-elige una opción-",
  "enterPhoneNumber": "Introduce tu número de teléfono",
  "profileSettings": "Ajustes del negocio",
  "saveChanges": "Guardar cambios",
  "learnMore": "Aprende más",
  "navigationLogout": "Cerrar sesión",
  "languages_en": "Inglés",
  "languages_de": "Alemán",
  "languages_es": "Español",
  "languages_fr": "Francés",
  "languages_ja": "Japonés",
  "languages_pt": "Portugués",
  "languages_ru": "Ruso",
  "languages_zh": "Chino",
  "languages_hi": "Hindi",
  "profileName": "Nombre del negocio",
  "uploadFilePlsWait": "Subiendo archivo, por favor espera",
  "browse": "Explorar",
  "chooseFileVideoOrImage": "Arrastra y suelta una imagen o un video",
  "fileSizeLimitation": "No más de {value} MB",
  "cover": "Portada",
  "toChooseFile": "para elegir un archivo",
  "dragDropImage": "Arrastra y suelta una imagen",
  "toChooseImage": "para elegir una imagen",
  "deleteCover": "Eliminar portada",
  "btnCreate": "Crear",
  "doYouWantDelete": "¿Realmente quieres eliminar?",
  "cancel": "Cancelar",
  "or": "o",
  "delete": "Eliminar",
  "update": "Actualizar",
  "btnUpdate": "Actualizar",
  "dashboardTitle": "Panel de control",
  "productTitle": "Productos",
  "noData": "No hay datos",
  "categoryName": "Nombre del catálogo",
  "categoryEdit": "Editar catálogo",
  "addNewProduct": "Agregar nuevo producto",
  "productCreate": "Nuevo producto",
  "name": "Nombre",
  "price": "Precio",
  "description": "Descripción",
  "mainPhoto": "Foto principal",
  "currency": "Moneda",
  "uploadAvatar": "Subir su avatar",
  "firstName": "Nombre",
  "lastName": "Apellido",
  "nickname": "Apodo",
  "email": "Correo electrónico",
  "chatBots": "Ventrix",
  "addNew": "Agregar nuevo",
  "btnSave": "Guardar",
  "createOptions": "Crear opciones",
  "editOptions": "Editar opciones",
  "btnAdd": "Agregar",
  "options": "Opciones",
  "ordersTitle": "Pedidos",
  "status": "Estado",
  "action": "Acción",
  "provider": "Proveedor",
  "customer": "Cliente",
  "orderId": "ID del Pedido",
  "direction": "Dirección",
  "pickupCode": "Código de Recogida",
  "countItems": "Cantidad de Productos",
  "telegramProvider": "Telegram",
  "whatsappProvider": "Whatsapp",
  "orderStatusPickedUp": "Recogido",
  "orderStatusClosed": "Cerrado",
  "orderStatusPending": "Pendiente",
  "orderStatusDelivered": "Entregado",
  "orderStatusProcessing": "En progreso",
  "orderStatusCanceled": "Cancelado",
  "reviews": "Opiniones",
  "pendingOrders": "Pedidos Pendientes",
  "totalDelivery": "Total de Entregas",
  "ordersHold": "Pedidos en Espera",
  "totalOrders": "Total de Pedidos",
  "totalEarned": "Total Ganado",
  "useDespaxoDrivers": "Usar Conductores de Ventrix",
  "useOurDrivers": "Usar Nuestros Propios Conductores",
  "currentLocation": "Ubicación Actual",
  "geoRadiusIncomingRequest": "Radio GEO para solicitudes entrantes",
  "managers": "Invitaciones",
  "createManager": "Crear Invitación",
  "registered": "Registrado",
  "yes": "Sí",
  "no": "No",
  "enabled": "Activado",
  "disabled": "Desactivado",
  "department": "Ubicación",
  "faqTitle": "Preguntas Frecuentes",
  "title": "Título",
  "editFaq": "Editar Preguntas Frecuentes",
  "createFaq": "Crear Preguntas Frecuentes",
  "faqDelete": "Eliminar Preguntas Frecuentes",
  "add": "Agregar",
  "enterLocationName": "Ingrese el nombre de la ubicación",
  "representative": "Representante",
  "permissionsDenied": "Permiso denegado",
  "permissionCreateBusiness": "Su paquete de usuario lo limita al uso de Ventrix. Para activar más perfiles, por favor, actualice a un paquete más adecuado",
  "permissionCreateBusinessForPremium": "Su paquete de Ventrix lo limita a 1 perfil de negocio. Para agregar perfiles adicionales, actualice a un paquete más adecuado.",
  "info": "Información",
  "useDespaxoDriverInfo": "Al seleccionar 'Usar Conductores de Ventrix', usted reconoce que cada pedido entregado costará ${price} por 1KM. Como Comerciante, puede acordar absorber este costo en su precio marcando la casilla 'Costo de Entrega incluido en el Pedido' o trasladar este costo de entrega a su cliente dejando la casilla sin marcar.",
  "faqWarning": "Tus preguntas frecuentes como comerciante deben incluir la siguiente pregunta. Como comerciante, debes crear tus propias preguntas frecuentes o expandir estas. Lo último que un cliente desea es sentirse confundido. Si esto sucede, no comprará tus productos. Por favor, haz tus preguntas frecuentes lo más claras posible con respuestas que garantizarás.",
  "getAnswer": "Obtener respuesta",
  "faqDefaultQuestion": "Preguntas frecuentes",
  "defaultFaqQuestion1": "¿Qué puedo hacer si mi pedido no llega?",
  "defaultFaqQuestion2": "¿Cuáles son mis opciones si mi pedido se retrasa, más de una hora?",
  "defaultFaqQuestion3": "¿Cómo cancelo mi pedido y cuáles son los costos de cancelación?",
  "defaultFaqQuestion4": "¿Cuál es la cantidad de tiempo que tengo para cancelar mi pedido sin cargo?",
  "defaultFaqQuestion5": "¿Qué puedo hacer si mi pedido se daña durante la entrega?",
  "defaultFaqQuestion6": "¿Qué puedo hacer si mi entrega no es el pedido correcto?",
  "navigationStore": "Tienda",
  "count": "contar",
  "amount": "Cantidad",
  "storeDelete": "Eliminar elemento de la tienda",
  "createStore": "Crear elemento de la tienda",
  "updateStore": "Actualizar elemento de la tienda",
  "none": "Ninguno",
  "showProductOptions": "Mostrar opciones de productos",
  "isRequired": "Requerido",
  "useCustomPrice": "Precio extra",
  "btnAddNew": "Agregar nuevo",
  "enterOptionTitle": "Ingrese el título de la opción",
  "createOptionsItem": "Agregar elemento de opción de combinación",
  "newTemplate": "Nueva plantilla",
  "editTemplate": "Editar plantilla",
  "templateQuestion": "Pregunta",
  "addConnections": "Agregar conexión",
  "selectConnectionItem": "Seleccionar elemento de conexión",
  "parentAnswer": "Conexión principal",
  "templateType": "Tipo de plantilla",
  "templateName": "Nombre de la plantilla",
  "templateText": "Texto",
  "addNewTemplate": "Agregar nueva plantilla",
  "answerHint": "Q/A deben crearse antes de agregarlos aquí a las conexiones. Todas las respuestas crean botones, por lo que cuanto más corta sea la respuesta, mejor se verá el chatbot.",
  "advancedOptions": "Opciones avanzadas",
  "show": "Mostrar",
  "followSchedule": "Seguir un horario en la configuración avanzada",
  "addNewFaq": "Agregar nueva FAQ",
  "isExtraPrice": "Usar precio extra",
  "demoMode": "Modo demo",
  "logoutDemoMode": "Cerrar sesión en el modo demo",
  "type": "Tipo",
  "parentConnection": "Conexión padre",
  "message": "Mensaje",
  "tags": "Etiquetas",
  "enterTagName": "Ingresar nombre de etiqueta",
  "enterHashtagName": "Ingresar nombre de hashtag",
  "search": "Buscar",
  "searchByHashtag": "Buscar por hashtag",
  "actionDisplayType": "Tipo de visualización de acción",
  "faqGroupsTitle": "Grupos de preguntas frecuentes",
  "faqDeleteGroup": "Eliminar grupo de preguntas frecuentes",
  "editFaqGroup": "Editar grupo de preguntas frecuentes",
  "createFaqGroup": "Crear grupo de preguntas frecuentes",
  "whatsAppWarningMessage": "Los mensajes y llamadas están cifrados de extremo a extremo. Nadie fuera de este chat, ni siquiera WhatsApp, puede leerlos o escucharlos. Toca para obtener más información.",
  "unreadMessage": "MENSAJE NO LEÍDO",
  "chooseCatalogs": "Elegir catálogos",
  "chooseFaqGroups": "Elegir grupos de preguntas frecuentes",
  "menu": "Menú",
  "back": "Volver",
  "orderDetail": "Detalles del pedido",
  "total": "Total",
  "support": "Soporte",
  "role": "Rol",
  "business": "Negocio",
  "selectRole": "Seleccionar rol",
  "chooseBusiness": "Elegir negocio",
  "createAdmin": "Crear administrador",
  "updateAdmin": "Actualizar administrador",
  "adminDelete": "Eliminar administrador",
  "managerDelete": "Eliminar gerente",
  "addQA": "Agregar Pregunta/Respuesta",
  "navigationManagerPanel": "Panel de gerente",
  "text": "Texto",
  "buttons": "Botones",
  "dropdown": "Desplegable",
  "createTemplateDesc": "¿Qué estás intentando hacer? ¿Vender un producto, crear un soporte, crear un horario, elegir una plantilla predefinida para ayudarte a empezar?",
  "availabilityReceiveOrders": "Disponibilidad para recibir pedidos",
  "chicken": "Pollo",
  "burger": "Hamburguesa",
  "noKetchup": "Sin Ketchup",
  "selectSchedule": "Seleccione un horario para mostrar el catálogo",
  "monday": "Lunes",
  "tuesday": "Martes",
  "wednesday": "Miércoles",
  "thursday": "Jueves",
  "friday": "Viernes",
  "saturday": "Sábado",
  "sunday": "Domingo",
  "faqGroup": "Grupo de preguntas frecuentes",
  "items": "productos",
  "potato": "Patata",
  "categoryLabel": "Categoría",
  "flowerShop": "Florería",
  "burgerHouse": "Casa de hamburguesas",
  "roundKilometers": "Kilómetros",
  "navigation": {
    "together": "Juntos",
    "signIn": "Iniciar sesión",
    "navPackages": "Paquetes",
    "faq": "Preguntas frecuentes",
    "about": "Acerca de",
    "privateApp": "Aplicación privada"
  },
  "enterOtp": "Ingrese el OTP",
  "serverError_login_otp_unconfirmed": "Código OTP no válido",
  "serverError_login_email": "Dirección de correo electrónico no válida",
  "enterCardData": "Ingrese los datos de la tarjeta",
  "payNow": "Pagar ahora",
  "nickname_exists": "Este apodo ya está en uso",
  "email_exists": "Este correo electrónico ya está en uso",
  "maxPaymentAmount": "El monto máximo de pago no debe ser superior a :amount",
  "error_access_auth": "Error de autorización",
  "walletTitle": "Billetera",
  "transaction": "Transacción",
  "walletSettings": "Configuración de billetera",
  "receiveFunding": "Recibir fondos",
  "sendFunding": "Enviar fondos",
  "addFunds": "Agregar fondos",
  "balance": "Saldo",
  "walletTransactions": "Transacciones",
  "payerAddress": "Dirección del pagador",
  "transferDate": "Fecha de transferencia",
  "noTransactions": "¡Sin transacciones!",
  "transactions": "Transacciones",
  "apiKey": "Clave API",
  "apiSecret": "Secreto de API",
  "walletLabel": "Al utilizar su tarjeta de crédito / débito para compras o transacciones, debe ingresar su información de pago. En ningún momento se le cobrará en su tarjeta hasta que haya autorizado y verificado su compra utilizando el código CVV de su tarjeta",
  "walletLabel2": "NOTA: TODOS LOS CARGOS APARECERÁN COMO X GROUP EN SU DECLARACIÓN.",
  "saveDebitCard": "Guardar tarjeta de débito",
  "navSettings": "Configuraciones",
  "walletReceive": "Recibir fondos",
  "copy": "Copiar",
  "account": "CUENTA",
  "formFirstName": "Nombre de pila",
  "formLastName": "Apellido",
  "formNickName": "Nombre de usuario",
  "walletNumber": "Número de billetera",
  "chooseOption": "Elija una opción",
  "descriptionOptional": "Descripción (opcional)",
  "buttonSend": "Enviar",
  "user": "Usuario",
  "walletAddFund": "Fondo rápido",
  "walletSendFunding": "Enviar fondos",
  "funding": "Financiación",
  "serviceCharge": "Cargo de servicio",
  "charge": "Cargo",
  "selectAmount": "Seleccionar cantidad",
  "btnClose": "Cerrar",
  "fromAddress": "Desde la dirección",
  "toAddress": "A la dirección",
  "transactionDetail": "Detalle de la transacción",
  "return": "Volver",
  "menuItem": "Elemento del menú",
  "orderNotes": "Notas. Por favor, entregar en la parte trasera de la residencia, puerta 32A",
  "returnTo": "Volver a",
  "faqGroups": "Grupos de preguntas frecuentes",
  "backToList": "Volver a la lista",
  "spagetti": "Espaguetis",
  "tomYum": "Tom Yum",
  "pizza": "Pizza",
  "salad": "Ensalada",
  "chickenWing": "Alitas de pollo",
  "templateFakeOrder_default": "Tu orden: <br>Artículo 1 - 10 (USD)<br>Artículo 2 - 8 (USD)<br>Artículo 3 - 5 (USD)<br><br>10:20 - la orden es aceptada<br>10:25 - preparación de la orden<br>10:40 - servicio de entrega<br><br>",
  "templateFakeOrder_44": "Tu orden: <br>PORSCHE MACAN TURBO M.2018 - 185000 (USD)<br><br>10:00 - la orden es aceptada<br>11:40 - servicio de entrega<br><br>",
  "templateFakeOrder_48": "Tu orden: <br>Perk Up Dry Shampoo - 80 (GTQ)<br>Heavy Duty Toenail Clippers - 69 (GTQ)<br><br>10:10 - la orden es aceptada<br>10:40 - servicio de entrega<br><br>",
  "templateFakeOrder_47": "Tu orden: <br>Pear Cut - 7900 (EUR)<br>Round Twisted - 5025 (EUR)<br><br>10:03 - la orden es aceptada<br>10:55 - servicio de entrega<br><br>",
  "templateFakeOrder_46": "Tu orden: <br>Bonpas Cotes Du Rhone 750ml - 122 (BRL)<br>Costilla de Res LB - 34.99 (BRL)<br><br>10:40 - la orden es aceptada<br>11:30 - servicio de entrega<br><br>",
  "templateFakeOrder_45": "Tu orden: <br>ASPIRINA FORTE 500MG/65MG - 101.6 (JPY)<br>VIROGRIP AM - 35 (JPY)<br><br>10:44 - la orden es aceptada<br>11:32 - servicio de entrega<br><br>",
  "templateFakeOrder_43": "Tu orden: <br>Mother's Day Special - 700 (GTQ)<br>Pink Roses & Chocolates - 600 (GTQ)<br><br>10:44 - la orden es aceptada<br>11:32 - servicio de entrega<br><br>",
  "templateFakeOrder_42": "Tu orden: <br>Margherita - 10 (USD)<br>Regina - 20 (USD)<br><br>9:50 - la orden es aceptada<br>10:23 - servicio de entrega<br><br>",
  "templateFakeOrder_394": "Tu orden: <br>Apple iPhone 13 Pro Max - 1599 (USD)<br>Green iPhone 13 Case - 18 (USD)<br><br>10:10 - la orden es aceptada<br>10:30 - servicio de entrega<br><br>",
  "templateFakeOrder_395": "Tu orden: <br>Empanada de Queso Crema - 10 (GTQ)<br>Strudel de Manzana 5 uni - 40 (GTQ)<br>Chapatona - 11 (GTQ)<br><br>10:10 - la orden es aceptada<br>11:00 - servicio de entrega<br><br>",
  "templateFakeOrder_397": "Tu orden: <br>Fruit Monster Mixed Berries - 190 (GTQ)<br>BGeekvape Aegis Boost Pro 100W - 440 (GTQ)<br><br>10:20 - la orden es aceptada<br>10:30 - servicio de entrega<br><br>",
  "templateFakeOrder_401": "Tu orden: <br>Cyber Secure ISP and VPN Services - 499 (GTQ)<br>Smart Door Lock-Classic - 179 (GTQ)<br><br>10:23 - la orden es aceptada<br>10:30 - servicio de entrega<br><br>",
  "templateFakeOrder_399": "Tu orden: <br>Cellucor C4 Ripped Pre-Workout - 45 (USD)<br>Boxing Classes - 75 (USD)<br><br>10:50 - la orden es aceptada<br>11:05 - servicio de entrega<br><br>",
  "templateFakeOrder_400": "Tu orden: <br>Artículo 1<br>Artículo 2<br>Artículo 3 <br><br>10:20 - la orden es aceptada<br>10:25 - preparación de la orden<br>10:40 - servicio de entrega<br><br>",
  "navigationDeleteDemoAccount": "Eliminar cuenta de demostración",
  "mobilePlugText": "Panel Ventrix solo accesible mediante una tableta o computadora.",
  "quantityLimits": "Límites de cantidad",
  "tooltipForQuantityLimits": "Limita la cantidad de opciones \"elegir\" disponibles para tus productos. Por ejemplo: (Título de la opción: \"Aderezos\") (\"Límites de cantidad\"): \"2\", tus opciones podrían ser Ketchup, Mayonesa, Pepinillos, Mostaza.",
  "labelVerifyBridge": "Conectar bot",
  "buttonConnect": "Conectar",
  "disconnect": "Desconectar",
  "scanQRFromWA": "Escanear código QR desde Whatsapp",
  "buttonClose": "Cerrar",
  "connections": "Conexiones",
  "connectionManager": "Administrador de conexiones",
  "connectToWa": "Conectar a Whatsapp",
  "connectToTg": "Conectar a Telegram",
  "connectionProviderTitle": "Proveedores de conexión",
  "confirmPhoneNumber": "Confirmar tu número de teléfono",
  "enterCodeFromTelegram": "Ingrese el código de Telegram",
  "enterCode": "Ingresar código",
  "enterPasswordFromTelegram": "Ingrese la contraseña de Telegram",
  "weOfferPickup": "Ofrecemos recogida",
  "deliverySettings": "Configuración de entrega",
  "deliverySettingsDesc": "Selecciona los tipos de entrega que estarán disponibles para los usuarios",
  "freeDeliveryHintForFixedDistance": "Después de {distance} km de entrega gratuita, la entrega costará ${amount}",
  "freeDeliveryHintForPerKm": "Después de {distance} km de entrega gratuita, la entrega costará ${amount} por cada 1 km",
  "generalManager": "Gerente General",
  "freeDeliveryRadius": "Radio de entrega gratuita",
  "hintForPayDelivery": "Establece el precio que cobrarás si la entrega gratuita supera tu radio.",
  "fixedDeliveryPrice": "Precio de entrega fijo",
  "pricePerKm": "Precio por km",
  "navigationPersonnel": "Personal",
  "all": "Todos",
  "create": "Crear",
  "freeDelivery": "Envío gratuito",
  "businessCoverDelivery": "Costo de envío compartido",
  "businessCoverDeliveryInfo": "Agregue el porcentaje que está dispuesto a pagar hacia el costo de envío. El cliente será facturado por la diferencia en porcentaje.",
  "deliveryPayClient": "Cliente paga el costo completo de envío",
  "deliveryPayClientInfo": "Cuando se selecciona, el cliente paga todos los costos de envío.",
  "freeDespaxoDelivery": "Ofrecer envío gratuito con Ventrix",
  "freeDespaxoDeliveryInfo": "Cuando se ofrece envío gratuito, el costo de envío se deducirá del pago al proveedor.",
  "selectLocationOnMap": "Selecciona tu ubicación en el mapa",
  "from": "Desde",
  "to": "Hasta",
  "fillOutSchedulePickup": "Completa el horario de recogida",
  "selectTemplate": "Seleccionar plantilla",
  "paymentSettings": "Configuración de pago",
  "paymentSettingsDesc": "Selecciona los tipos de pagos disponibles para los usuarios",
  "xprAccountAddress": "Dirección de cuenta XPR",
  "useOnline": "Usar Stripe",
  "useXPR": "Usar XPR",
  "save": "Guardar",
  "useMia": "Usar MIA Vivid",
  "validationError_required": "Por favor, complete el campo {name}",
  "additionalOptions": "Opciones adicionales",
  "useMiaHint": "Utiliza la inteligencia artificial MIA Vivid en tu chatbot para ayudar a los clientes a elegir productos, realizar pedidos, hacer preguntas, etc., en forma de diálogo interactivo. Todas las funciones y conexiones del chatbot en la plantilla se convertirán en un diálogo interactivo con el cliente.",
  "payment_status_pending": "Pendiente",
  "payment_status_confirmed": "Confirmado",
  "payment_status_rejected": "Rechazado",
  "payment_status_cancelled": "Cancelado",
  "payment_status_refunded": "Reembolsado",
  "payment_status_user_ready_to_pay": "Usuario listo para pagar",
  "orderStatus": "Estado del pedido",
  "orderStatusInProcessing": "En procesamiento",
  "pickUp": "Para recoger",
  "address": "Dirección",
  "inviteStatus": "Estado de invitación",
  "invitePending": "Pendiente",
  "inviteAccept": "Aceptado",
  "resendInvite": "Reenviar invitación",
  "successResendInvite": "La invitación se envió nuevamente con éxito",
  "successCopyToClipboard": "Éxito copiado al portapapeles",
  "walletAddress": "Dirección de billetera",
  "addWalletAddress": "Agregar dirección de billetera",
  "walletAddressError": "Se produjo un error al crear la ubicación. El propietario del negocio no ha creado una billetera, infórmale o ingresa la dirección de billetera tú mismo:",
  "dontHavePermissionsForDepartment": "Aún no tienes acceso a esta ubicación. Espera a que el administrador te agregue a la ubicación.",
  "chatBotUsername": "Nombre de usuario del ChatBot Ventrix",
  "validationField_username": "Ingresa un nombre de usuario válido",
  "warning": "Advertencia",
  "continue": "Continuar",
  "createNewMatrixAccountWarning": "Se te ha asignado el rol de gestor de conexiones. Para el funcionamiento de los chatbots de Ventrix, se cerrará la sesión de tus cuentas personales de mensajería. Debes iniciar sesión nuevamente en tus mensajeros para iniciar el chatbot de Ventrix.",
  "selectBusiness": "Seleccionar negocio",
  "validationError_cannotAddYourself": "No puedes añadirte a ti mismo aquí",
  "validationError_managersAlreadyExist": "Este correo electrónico ya existe en el sistema",
  "markers": "Marcadores",
  "cantCreateMoreLocations": "No puedes añadir más ubicaciones",
  "createNewDepartments": "Crear nuevo ubicación",
  "locations": "Ubicaciones",
  "locationAlreadyExist": "Esta ubicación ya existe",
  "enoughLocationCount": "Selecciona al menos una ubicación en el mapa",
  "businessLevelSmall": "Pequeño",
  "businessLevelMedium": "Medio",
  "businessLevelBig": "Grande",
  "businessLevelSmallOption_1": "1 departamento",
  "businessLevelSmallOption_2": "10 puntos de venta por departamento",
  "businessLevelMediumOption_1": "10 departamentos",
  "businessLevelMediumOption_2": "Número ilimitado de puntos de venta por departamento",
  "businessLevelBigOption_1": "Número ilimitado de departamentos",
  "businessLevelBigOption_2": "Número ilimitado de puntos de venta por departamento",
  "businessLevelOption_3": "Bot 24/7 *",
  "informationForLevel3": "Esta opción solo está disponible para paquetes de pago, como (Premium, Premium+, Empresa)",
  "chooseOptionType": "Elige el tipo de opción:",
  "comboOption": "Combo",
  "extraOption": "Extra",
  "serverError_xpr_address_invalid_address": "La dirección xpr debe ser una dirección de billetera Hola",
  "serverError_xpr_address_required": "Por favor, completa este campo",
  "createConnectionManager": "Crear Conexiones",
  "haveWAConnection": "Ya tienes conexión en WhatsApp",
  "haveTGConnection": "Ya tienes conexión en Telegram",
  "language": "Idioma",
  "fixedCurrency": "Moneda fija",
  "currencyRate": "Tipo de cambio",
  "myWorkspace": "Mi Espacio de Trabajo",
  "workspace": "Espacio de Trabajo",
  "navigationMessages": "Mensajes",
  "comingSoon": "Próximamente",
  "userContacts": "Contactos de Usuario",
  "saveContact": "Guardar contacto",
  "businessTypeSmallListItem1": "Un perfil de negocio activo",
  "businessTypeSmallListItem2": "Preguntas frecuentes y catálogos ilimitados activos",
  "businessTypeSmallListItem3": "Un área de 15KM",
  "businessTypeSmallListItem4": "10 ubicaciones de tienda limitadas por cada 15KM",
  "businessTypeSmallListItem5": "Procesamiento de tarjetas 20%",
  "businessTypeSmallListItem6": "Uso ilimitado para todos los usuarios de Hola",
  "businessTypeMediumListItem1": "Perfiles de negocio activos ilimitados",
  "businessTypeMediumListItem2": "Preguntas frecuentes, calendarios y catálogos ilimitados activos",
  "businessTypeMediumListItem3": "Diez áreas de 15KM",
  "businessTypeMediumListItem4": "Ubicaciones de tienda ilimitadas por cada 15KM",
  "businessTypeMediumListItem5": "Procesamiento de tarjetas 15%",
  "businessTypeMediumListItem6": "Uso ilimitado para todos los usuarios premium de Hola",
  "businessTypeBigListItem1": "Perfiles de negocio activos ilimitados",
  "businessTypeBigListItem2": "Preguntas frecuentes, calendarios y catálogos ilimitados activos",
  "businessTypeBigListItem3": "Área de 15KM ilimitada",
  "businessTypeBigListItem4": "Envío global",
  "businessTypeBigListItem5": "Ubicaciones de tienda ilimitadas por cada 15KM",
  "businessTypeBigListItem6": "MIA Vivid",
  "businessTypeBigListItem7": "Procesamiento de tarjetas 10%",
  "businessTypeBigListItem8": "Uso ilimitado para todos los usuarios premium plus de Hola",
  "transactionId": "ID de transacción",
  "transactionHash": "Hash de transacción",
  "subtotal": "Subtotal",
  "deliveryFee": "Costo de Entrega",
  "paymentStatus": "Estado de pago",
  "senderInfo": "Información del remitente",
  "fullName": "Nombre completo",
  "userName": "Nombre de usuario",
  "schedule": "Horario",
  "scheduleSettings": "Configuración de horario",
  "aroundClock": "Las 24 horas del día",
  "order": "Pedido",
  "host": "Anfitrión",
  "deliveryMethod": "Método de entrega",
  "customerComment": "Comentario",
  "transactionNumber": "Numero de Transaccion",
  "courier": "Courier",
  "pickUpAddress": "Direccion de Recogida",
  "deliveryAddress": "Direccion de Entrega",
  "thankYouComeAgain": "Gracias por su Compra",
  "upgradeYourPackage": "Actualiza tu paquete",
  "upgrade": "Actualizar",
  "upgradePackageText1": "Lamentablemente, tu paquete de suscripción actual no es adecuado para este servicio. Por favor, verifica tu suscripción y actualízala si deseas acceder a este servicio.",
  "upgradePackageText2": "Has excedido los límites de tu paquete actual, y se recomienda considerar su actualización.",
  "break": "Descanso",
  "workingHours": "Horas de trabajo",
  "packageMoreDetailLink": "Puedes ver otras opciones de paquetes",
  "upgradePackageText3": "No tienes una suscripción para este tipo de negocio, pero puedes comprar una si eliges y compras el paquete relevante",
  "here": "aquí",
  "successUpdate": "Éxito al actualizar",
  "successCreate": "Éxito al crear",
  "successDelete": "Éxito al eliminar",
  "noResultFound": "No se encontraron resultados",
  "enterWorkspaceName": "Ingresa el nombre del espacio de trabajo",
  "businessTypeProduct": "Producto",
  "businessTypeService": "Servicio",
  "appointmentModeOrderForm": "Formulario de pedido",
  "appointmentModeOnline": "Check-in en línea",
  "appointmentModeCalendar": "Calendario, citas, horarios",
  "customerAppointmentMode": "Modo de cita del cliente",
  "onSiteService": "Servicio en el lugar",
  "serviceRoles": "Roles de servicio",
  "serviceRoleName": "Nombre del rol de servicio",
  "roleDelete": "Eliminar rol",
  "profession": "Profesión",
  "professions": "Profesiones",
  "phone": "Teléfono",
  "skills": "Habilidades",
  "skill": "Habilidad",
  "duration": "Duración",
  "durationMin": "Duración, min",
  "actualExecutionTime": "Tiempo de ejecución real",
  "executionTime": "Tiempo de ejecución",
  "measure": "Medida",
  "services": "Servicios",
  "service": "Servicio",
  "category": "Categoría",
  "updateService": "Actualizar servicio",
  "addService": "Agregar servicio",
  "workSchedule": "Horario de trabajo",
  "sector": "Sector",
  "ordersCreatedAt": "Orden creada en",
  "productName": "Nombre del producto",
  "extraOptions": "Opciones adicionales",
  "printReceipt": "Imprimir recibo",
  "progress": "Progreso",
  "cannotProcess": "No se puede procesar",
  "reason": "Motivo",
  "internalNotes": "Notas internas",
  "expectedResolutionDate": "Fecha de resolución esperada",
  "followUpRequired": "Seguimiento requerido",
  "assignedTo": "Asignado a",
  "priority": "Prioridad",
  "customerPreference": "Preferencia del cliente",
  "preemptiveRefusal": "Rechazo preventivo",
  "deliveredOrPerformed": "Entregado o realizado",
  "modified": "Modificado",
  "cancelled": "Cancelado",
  "addNewAdditionalOptions": "Agregar nuevas opciones adicionales",
  "products": "Productos",
  "rating": "Calificación",
  "deliveryMode": "Modo de entrega",
  "preDeliveryStatus": "Estado previo a la entrega",
  "deliveryStatus": "Estado de entrega",
  "postDeliveryStatus": "Estado posterior a la entrega",
  "deliveryShipmentStatus": "Estado de envío de entrega",
  "driverNotificationStatus": "Estado de notificación del conductor",
  "customerNotificationResponse": "Respuesta de notificación al cliente",
  "progressCustomerStatus": "Estado de progreso del cliente",
  "disputeStatus": "Estado de disputa",
  "detail": "Detalle",
  "close": "Cerrar",
  "successAdded": "Añadido con éxito",
  "successReplaced": "Reemplazado con éxito",
  "subsProducts": "Productos de sustitución",
  "oldProducts": "Productos antiguos",
  "assetReturnTime": "Hora de devolución de activos",
  "assetReleaseTime": "Hora de liberación de activos",
  "selectTime": "-seleccione la hora-",
  "assignedStaffFeedback": "Comentarios del personal asignado",
  "assetStatus": "Estado del activo",
  "driver": "Conductor",
  "assetCondition": "Estado del activo",
  "packagingType": "Tipo de embalaje",
  "expectedCompletionTime": "Tiempo de finalización esperado",
  "assetItemsStatus": "Estado de los elementos del activo",
  "assetDocumentsInspectionStatus": "Estado de inspección de documentos del activo",
  "immediateActions": "Acciones inmediatas",
  "probableCause": "Causa probable",
  "followUpDate": "Fecha de seguimiento",
  "evidenceAttachments": "Adjuntos de evidencia",
  "clientCommunicationStatus": "Estado de comunicación con el cliente",
  "resolutionStatus": "Estado de resolución",
  "escalationNeeded": "Necesidad de escalación",
  "longTermSolutionsProposed": "Soluciones a largo plazo propuestas",
  "clientFeedback": "Opinión del cliente",
  "causeIdentified": "Causa identificada",
  "severityLevel": "Nivel de gravedad",
  "relatedAssets": "Activos relacionados",
  "reportedBy": "Reportado por",
  "affectedParties": "Partes afectadas",
  "resolutionDescription": "Descripción de la resolución",
  "new": "Nuevo",
  "inProgress": "En progreso",
  "problem": "Problema",
  "completed": "Completado",
  "notificationBusinessText": "Tiene {length} nuevos pedidos en el negocio {name}",
  "replace": "Reemplazar",
  "selected": "Seleccionado",
  "dispute": "Disputa",
  "clientVerificationStatus": "Estado de verificación del cliente",
  "clientFeedbackDate": "Fecha de retroalimentación del cliente",
  "mediationRequired": "Mediación requerida",
  "merchantResponseDate": "Fecha de respuesta del comerciante",
  "raisedBy": "Elevado por",
  "resolutionAttempts": "Intentos de resolución",
  "resolutionProposedByDespaxo": "Resolución propuesta por Ventrix",
  "sellerResponse": "Respuesta del vendedor",
  "finalResolution": "Resolución final",
  "acceptedResolution": "Resolución aceptada",
  "validationField_requiredTrue": "Por favor, verifique este campo",
  "orderStatuses": "Estados del pedido",
  "preDeliveryStatuses": "Estados previos a la entrega",
  "deliveryStatuses": "Estados de entrega",
  "edit": "Editar",
  "uploadMultipleFileHint": "Los artículos requieren un mínimo de (1) imagen principal - (máximo de 6 imágenes) 150 px x 150 px. Tu primera imagen cargada será la imagen principal que verán primero los clientes que vean tus artículos.",
  "enterProductName": "Ingresar nombre del producto",
  "selectProduct": "Seleccionar producto",
  "createdAt": "Creado en",
  "orderAmount": "Cantidad",
  "orderAppealStatus": "Estado del reclamo",
  "orderModerationStatus": "Estado de moderación",
  "departureTime": "Hora de salida",
  "estimatedArrivalTime": "Hora estimada de llegada",
  "itemConditions": "Condiciones",
  "pickupConfirmation": "Confirmación de recogida",
  "pickupTime": "Hora de recogida",
  "expectedDeliveryTime": "Hora estimada de entrega",
  "trafficConditions": "Condiciones de tráfico",
  "waitingStartTime": "Hora de inicio de espera",
  "contactsAttempts": "Intentos de contacto",
  "deliveryTime": "Hora de entrega",
  "code": "Código",
  "recipientName": "Nombre del destinatario",
  "deliveryConfirmation": "Confirmación de entrega",
  "conditionOnDelivery": "Condición en la entrega",
  "clientNotification": "Notificación al cliente",
  "newEstimatedDeliveryTime": "Nueva hora estimada de entrega",
  "estimatedDelayMinutes": "Minutos de retraso estimados",
  "incidentDescription": "Descripción del incidente",
  "timeOfIncident": "Hora del incidente",
  "reschedulingPlan": "Plan de reprogramación",
  "impactOnDelivery": "Impacto en la entrega",
  "immediateActionsTaken": "Acciones inmediatas tomadas",
  "problemReportTime": "Hora de informe del problema",
  "shouldDelivery": "Tiempo de entrega",
  "mapBusinessHint": "Selecciona la ubicación de tu negocio",
  "selectDriverLocation": "Selecciona la ubicación del conductor",
  "selectNewLocationDestination": "Selecciona una nueva ubicación de destino",
  "visibleName": "Nombre visible",
  "maxFutureReservationLimitHour": "Límite máximo de reserva futura en horas",
  "employee": "Empleado",
  "assets": "Activos",
  "asset": "Activo",
  "internalId": "ID interno",
  "breakTime": "Tiempo de descanso",
  "breakTimeUnit": "Unidad de tiempo de descanso",
  "sessionOrServiceDurationTime": "Tiempo de duración de sesión o servicio",
  "sessionOrServiceUnit": "Unidad de tiempo de sesión o servicio",
  "isActive": "Activo",
  "minute": "Minuto",
  "hour": "Hora",
  "day": "Día",
  "unit": "Unidad",
  "minUnitReservation": "Tiempo mínimo de reserva",
  "maxUnitReservation": "Tiempo máximo de reserva",
  "problemDescription": "Descripción del problema",
  "proposedResolution": "Propuesta de solución",
  "totalOrderPrice": "Precio del pedido",
  "weOfferGlobalDelivery": "Ofrecemos entrega mundial",
  "global": "Global",
  "own": "Propio",
  "pickup": "Recoger",
  "despaxo": "Ventrix",
  "orderDeliveryTime": "Tiempo de entrega del pedido",
  "eventEndTime": "Hora de finalización del evento",
  "eventStartTime": "Hora de inicio del evento",
  "trackingNumber": "Número de seguimiento",
  "courierServices": "Servicios de mensajería",
  "globalDelivery": "Envío global",
  "resourceTypeHuman": "¿Es recurso humano?",
  "appointments": "Citas",
  "startTime": "Hora de inicio",
  "endTime": "Hora de finalización",
  "validationField_integer": "Por favor, introduce un número entero.",
  "scheduler": "Planificador",
  "catalogServices": "Servicios del Catálogo",
  "companyIdField": "ID de la Empresa (es decir, SKU o Número de Producto)",
  "employerServicesTitle": "Selecciona los servicios que el empleado realizará",
  "downtime": "Tiempo de inactividad",
  "timeUnit": "Unidad de tiempo",
  "nameSchedule": "Nombre del horario",
  "dayLower": "día",
  "daysLower": "días",
  "scheduleName": "Nombre del horario",
  "addNewSchedule": "Agregar horario del producto",
  "eventInfo": "Información del evento",
  "reservationLimit": "Reservation Limit",
  "reservationLimitHint": "This signifies how far in advance customers can schedule time with this persons schedule.",
  "markAsProduct": "Clasificar como Producto",
  "hintForAssetImages": "",
  "setRangeForService": "Establecer rango para el servicio",
  "descForRangeService": "Esta opción permite a los usuarios elegir la fecha del servicio dentro de un rango específico de días (desde y hasta, inclusive). Puede ser beneficiosa para varios servicios como alquiler de coches (desde y hasta) o reservas de hoteles (desde y hasta), entre otros.",
  "comment": "Comentario",
  "employers": "Empleados",
  "employer": "Empleado",
  "employerImages": "Imágenes de empleados",
  "refund": "Reembolso",
  "complete": "Completado",
  "appointment": "Cita",
  "reply": "Responder",
  "addReply": "Agregar respuesta",
  "editReply": "Editar respuesta",
  "enterReplyForReview": "Ingrese su respuesta al comentario aquí...",
  "yourSavedCard": "Tu tarjeta guardada",
  "dateAdded": "Fecha de agregado",
  "useNewCard": "Usar nueva tarjeta",
  "useSavedCard": "Usar tarjeta guardada",
  "selectCard": "Seleccionar tarjeta",
  "purchaseFund500": "Se produjo un error durante el pago. Por favor, intenta hacer el pago más tarde",
  "fee": "Tarifa",
  "validationField_not_exists": "Usuario con este apodo no encontrado",
  "validationField_minValue": "El valor mínimo es {min}",
  "validationTransfer_not_exists": "Durante la transferencia de dinero, surgió un problema debido a la ausencia de una billetera electrónica creada para el destinatario.",
  "transferFunds500": "Durante el proceso de transferencia de fondos, surgió una situación inesperada que impidió completar la transacción en este momento",
  "appointmentAdd": "Agregar cita",
  "serviceLocation": "Ubicación del servicio",
  "selectOptions": "Opciones",
  "selectService": "Seleccionar servicio",
  "placeholderSelectService": "Ingrese el nombre del servicio",
  "fieldGeolocationTooltip": "Elige un radio para tu negocio donde los clientes pueden realizar pedidos",
  "fieldParentConnectionTooltip": "Nodo después de seleccionar el cual se ubicará la opción para cambiar a la plantilla actual",
  "fieldChooseCatalogTooltip": "Selecciona categorías de productos o servicios que estarán disponibles para los clientes en el sitio web externo",
  "fieldDepartmentTemplateIdTooltip": "Selecciona una plantilla para cómo tu bot responderá a los clientes. No olvides agregar catálogos y preguntas frecuentes a la plantilla que estarán disponibles para los usuarios durante su conversación con el bot. Si no tienes plantillas, necesitas crearlas en la sección 'Plantillas'.",
  "fieldChooseFaqTooltip": "Selecciona grupos de preguntas frecuentes que estarán disponibles para los usuarios en el sitio web externo",
  "chatsDepartment": "Chat",
  "searchByChat": "Buscar por chat",
  "provideLiveSupport": "Este negocio ofrece soporte en tiempo real",
  "coverPhoneAllDepartments": "Un número de teléfono cubrirá todos los departamentos",
  "supportPhone": "Soporte en vivo",
  "supportPhoneTooltip": "Este campo contiene información sobre el número de teléfono de soporte que se utilizará en el chatbot como medio principal de comunicación con el gerente de soporte. Está destinado a proporcionar a los usuarios la capacidad de buscar asistencia o consulta si es necesario",
  "selectedDate": "Fecha seleccionada",
  "currentAppointmentInfo": "Información actual de la cita",
  "editAppointment": "Editar cita",
  "despaxoDeliveryDoesntWorkForRegionWarning": "Desafortunadamente, la entrega no está disponible actualmente en la región seleccionada. Planeamos expandir nuestra cobertura en el futuro para habilitar la entrega en esta área.",
  "logo": "Logo",
  "answer": "Respuesta",
  "question": "Pregunta",
  "signUp": "Registrarse",
  "login": "Iniciar sesión",
  "salesRep": "Representante de ventas",
  "package": "Paquete",
  "loginTitle": "Inicio de sesión de Ventrix Admin",
  "loginSubtitle": "Inicie sesión con su correo electrónico. Se le enviará un código de autorización a su correo electrónico. Asegúrese de que su correo electrónico sea correcto y verifique su carpeta de SPAM si no lo recibe en un minuto.",
  "signIn": "Iniciar sesión",
  "dontHaveAcc": "¿No tienes una cuenta?",
  "alreadyHaveAcc": "¿Ya tienes una cuenta?",
  "registrationTitle": "¡Regístrese y descargue GRATIS!",
  "registrationSubtitle": "Siempre disfrute de la versión básica GRATUITA, simplemente regístrese y descargue para comenzar.",
  "submit": "Enviar",
  "buy": "Comprar",
  "acceptAgreementShort": "Acepto todos los",
  "privacyPolicies": "Políticas de privacidad",
  "and": "y",
  "profile": "Perfil",
  "packages": "Paquetes",
  "avatar": "Avatar",
  "upgradePackage": "Actualizar paquete",
  "yourPackage": "Tu paquete",
  "saasSettings": "Configuraciones de SaaS",
  "saasWarningText": "Su paquete actual de {package} permite {size} de almacenamiento para archivos de documentación e imágenes. Para obtener más datos, por favor actualice su paquete.",
  "used": "usado",
  "free": "gratis",
  "storage": "Almacenamiento",
  "whiteLabel": "Etiqueta blanca",
  "externalLogo": "Logo externo",
  "newVersionAvailable": "Nueva versión disponible",
  "admin": "Controles administrativos",
  "navigationHome": "Panel de control",
  "navigationAdmins": "Administradores del sitio",
  "personnel": "Usuarios autorizados",
  "setup": "Configuración de plantilla",
  "navigationCatalog": "Plantillas de productos",
  "navigationFaq": "Plantillas de preguntas",
  "navigationScheduler": "Plantillas de programación",
  "workingSchedules": "Horas de trabajo",
  "navigationTemplates": "Plantillas de robots",
  "messages": "Centro de mensajes",
  "navigationFeedbacks": "Comentarios",
  "navigationReviews": "Raitings",
  "orders": "Pedidos",
  "navigationOrders": "Órdenes",
  "calendar": "Contabilidad",
  "departments": "Sucursales",
  "departmentLabel": "Sucursal",
  "navigationProfiles": "Perfiles comerciales",
  "bookings": "Reservas",
  "accounting": "Contabilidad",
  "dataProcessingAgreement": "Acuerdo de Procesamiento de Datos",
  "termsAndConditions": "Términos y Condiciones",
  "accept": "Aceptar",
  "usernameAlreadyExist": "Este nombre de usuario ya está en uso",
  "send": "Enviar",
  "serverError_login_auth": "Error de autenticación en el inicio de sesión",
  "today": "Hoy",
  "yesterday": "Ayer",
  "privateAndSecure": "Privada y Seguro",
  "privateAndSecureText1": "Ventrix AI aboga por la privacidad como un derecho humano esencial, especialmente en el mundo actual. Para mantener esta creencia, hemos creado nuestra propia infraestructura tecnológica, incluidas API, para salvaguardar nuestros datos y la información de los usuarios. Estamos comprometidos a mantener una postura neutral sobre opiniones políticas y religiosas. Por lo tanto, consideramos que las API de terceros, en particular aquellas de infractores de privacidad corporativos que venden datos, son inseguras e inadecuadas para nuestra plataforma. En consecuencia, priorizamos la construcción de nuestros propios conceptos de software y la implementación de la automatización para minimizar los errores humanos.",
  "privateAndSecureText2": "En Ventrix, creemos firmemente que nada viene sin costo y siempre cumplimos nuestras promesas. La privacidad y la seguridad son el núcleo de nuestros valores. Para garantizar el máximo anonimato y protección del usuario, permitimos el registro sin revelar datos personales ni nombres, aunque se requiere una dirección de correo electrónico activa (que también puede ser anónima). El nombre de usuario elegido sirve como medio principal para conectar perfiles dentro de nuestro sistema.",
  "privateAndSecureText3": "Para fortalecer nuestras medidas de seguridad, aprovechamos una combinación de herramientas premium y nuestras cerraduras y llaves patentadas. Nuestra integración incluye websockets, DTLS (Seguridad de la capa de transporte de datagramas) y UDP (Protocolo de datagramas de usuario). Empleamos el proxy MITM (Hombre-en-el-medio) para protegernos contra vulnerabilidades externas, incluidas aquellas dentro del sistema de registro de usuarios de Ventrix. Utilizamos HTTPS y WSS (WebSockets) para aplicar permisos que se alinean con los requisitos de nuestro sistema.",
  "privateAndSecureText4": "En Ventrix, priorizamos los puntos de entrada seguros para garantizar la máxima seguridad a nuestros usuarios.",
  "checkIn": "Registrarse",
  "loginBlockTitle": "Involucre a los clientes de su aplicación de chat al instante usando robot de IA",
  "loginBlockText1": "con ventrix, mejorar y amplificar el crecimiento de su marca con conversaciones automatizadas en plataformas de mensajería populares como WhatsApp, Telegram, Instagram y Facebook Messenger, entre otras. Centralice todas las plataformas en un solo lugar sin costo de API. ",
  "loginBlockText2": "Impulse las ventas en línea instantáneas con catálogos, preguntas frecuentes, programación, participación del cliente y generación de clientes potenciales mediante la implementación de conversaciones automatizadas utilizando plantillas y respuestas de dispersión. ",
  "loginBlockText3": "Ofrezca sin esfuerzo experiencias personalizadas, soporte instantáneo e interacciones automatizadas para maximizar el éxito de su marca y facilitar transacciones en línea sin problemas. ",
  "loginBlockText4": "Si necesita una demostración de nuestra plataforma o tiene alguna pregunta, nuestro equipo de asesores está disponible para brindarle asistencia. No dude en comunicarse con nosotros",
  "domainSettings": "Configuración de dominio",
  "whiteLabelNotAvailableWarning": "Tu paquete actual no incluye esta opción. Si deseas obtenerla, actualiza tu paquete.",
  "downgradeInfo": "Tu paquete {currentPackage} caducó en {expired}, y volverá automáticamente a Básico",
  "yourDomain": "Tu subdominio",
  "previewYourSiteUrl": "Vista previa de la URL de tu sitio",
  "changeDomain": "Cambiar dominio",
  "domainSlug": "Identificador del dominio",
  "maxLimitAdminsForPackage": "El número máximo de administradores que puedes crear es <strong>{count}</strong>. Para aumentar este límite, actualiza tu paquete.",
  "license": "Licencia",
  "expired": "Caducado",
  "packageOptions": "Opciones de paquete",
  "addOnPackages": "Paquetes adicionales",
  "packageInfo": "Información del paquete",
  "licenseOwner": "Propietario de la licencia",
  "supportPackageName": "Soporte",
  "supportPackageDesc": "Recibe soporte en tiempo real con pantalla en vivo durante un año para tus productos SAAS.",
  "devPackageName": "Desarrollo",
  "devPackageDesc": "Nuestro equipo configurará tu identidad para el lanzamiento. Proporciónanos tu contenido, incluyendo un máximo de 100 productos, preguntas frecuentes y horarios, y nosotros nos encargaremos del resto. Todo lo que tienes que hacer es conectar a tu equipo.",
  "contacts": "Contactos",
  "trainingDocumentation": "Documentación de entrenamiento",
  "salesAssociate": "Asociado de ventas",
  "salesManager": "Gerente de ventas",
  "lastUpdates": "Actualizaciones",
  "verifyYourEmailTitle": "Verifica tu correo electrónico",
  "verifyYourEmailDesc": "¿No recibiste un correo electrónico con tu código? Revisa tu carpeta de spam o comienza de nuevo ingresando un correo electrónico válido.",
  "introBusinessEmptyPageText": "¡Felicidades por registrarte en Ventrix AI Chatbot! Al emprender tu viaje para construir tu chatbot, entendemos que empezar puede ser confuso. Pero no te preocupes, al igual que andar en bicicleta por primera vez, puede parecer difícil al principio, pero una vez que aprendas, lo usarás con facilidad.<br><br>Para brindarte experiencias fluidas y personalizadas, ofrecemos soporte en línea de pago. Además, si prefieres una experiencia sin complicaciones, podemos desarrollar todo el sistema de tu chatbot por ti, incluyendo menos de 100 productos, por una tarifa pequeña y en poco tiempo. Piénsalo como obtener una bicicleta con rueditas de entrenamiento. Si estás interesado en mejorar tu paquete o agregar desarrollo completo, por favor contacta a tu representante de ventas, y te ayudarán a hacer los arreglos necesarios.<br><br>Si prefieres empezar por tu cuenta, comienza añadiendo tu primer perfil. Puedes hacerlo haciendo clic en el botón \"Agregar Nuevo\" ubicado en la esquina superior derecha de la pantalla. Una vez que hayas creado tu perfil, puedes acceder a él para abrir las opciones de menú del perfil. Diseñados para facilitarte el proceso, cada enlace de perspectiva comienza con una pantalla de ayuda e incluye muestras o comentarios al pasar el cursor para guiarte en la construcción de tu primer chatbot.<br><br>Para ayudarte aún más, también proporcionamos videos de entrenamiento en nuestro canal de YouTube en <a href='{link}' target='_blank' class='blue-color'>{link}</a>. Estos videos te brindarán una guía visual y te ayudarán a navegar a través de las diversas características y funcionalidades de Ventrix AI Chatbot.",
  "downloadOurApp": "Descarga nuestra aplicación",
  "confirmOtpTitle": "Verifica tu correo electrónico",
  "confirmOtpDesc": "¿No recibiste un correo electrónico con tu código? Revisa tu carpeta de spam o comienza de nuevo ingresando un correo electrónico válido.",
  "signInText": "Estimado usuario,<br><br>Ventrix es una aplicación de escritorio/tablet SaaS, aunque permitimos registros a través del sitio web, lamentablemente, no admitimos una versión web en este momento. Te invitamos a descargar la aplicación de escritorio donde podrás iniciar sesión después de registrarte en la web.<br><br>Por favor, sigue el enlace a continuación para descargar la aplicación de escritorio que mejor se adapte a tu sistema operativo<br><br>Gracias por tu comprensión y por elegir nuestra plataforma.<br><br>Atentamente, Ventrix",
  "successRegisterTitle": "¡Felicidades por registrarte exitosamente!",
  "successRegisterWithoutSalesLink": "Estimado usuario,<br><br>Gracias por registrarte en Ventrix. Aunque ofrecemos registros en el sitio web, queremos informarte que Ventrix es una aplicación de escritorio/tablet SaaS, y está disponible exclusivamente a través de distribuidores revendedores.<br><br>Para garantizar una experiencia fluida para ti, te pedimos amablemente que envíes un correo electrónico a <a class='blue-color' href='mailto:{email}'>{email}</a>. Nuestro equipo te conectará con un distribuidor local que pueda ayudarte aún más. Por favor, permítenos 24 horas para hacer los arreglos necesarios. Nos disculpamos por cualquier inconveniente que esto pueda causar.<br><br>Gracias nuevamente por elegir nuestra plataforma. Apreciamos tu comprensión.<br><br>Atentamente, Ventrix",
  "successRegisterWithSalesLink": "Nos complace darle la bienvenida a nuestra comunidad. Ahora tienes acceso a todas nuestras funciones y oportunidades.<br><br>Para continuar tu viaje, te recomendamos descargar nuestra aplicación. Esto te permitirá disfrutar de todos los beneficios de nuestro servicio directamente en tu dispositivo. Con la aplicación, puedes usar nuestros servicios de manera conveniente en cualquier momento y lugar.<br><br>¡No esperes más! ¡Descarga la aplicación ahora y únete a nosotros en una aventura emocionante y atractiva.<br><br>¡Gracias por elegirnos!",
  "signUpText": "Gracias por su interés en Ventrix. Aunque ofrecemos registros en el sitio web, queremos informarle que Ventrix es una aplicación de escritorio/tablet SaaS y está disponible exclusivamente a través de distribuidores revendedores.<br><br>Para garantizar una experiencia fluida para usted, le pedimos amablemente que envíe un correo electrónico a <a class='blue-color' href='mailto:{email}'>{email}</a>. Nuestro equipo lo pondrá en contacto con un distribuidor local que pueda ayudarlo más. Por favor, permítanos 24 horas para hacer los arreglos necesarios. Nos disculpamos por cualquier inconveniente que esto pueda causar.<br><br>Gracias nuevamente por elegir nuestra plataforma. Apreciamos su comprensión.",
  "downloadFor": "Descargar para",
  "congratulations": "Felicidades",
  "inviteSuccessText": "Tu invitación ha sido confirmada exitosamente. Ahora eres parte de nuestro negocio N como rol R. Gracias por unirte a nuestro equipo. ¡Te deseamos éxito en tu nuevo rol!",
  "invite": "Invitar",
  "introAnyEmptyPageText": "Para comenzar tu viaje con el software Ventrix Chatbot, simplemente haz clic en el enlace \"Agregar Nuevo\" ubicado en la esquina superior derecha. Si necesitas instrucciones en video completas sobre cómo aprovechar al máximo el software, te invitamos a visitar nuestra sección de entrenamiento en YouTube en <a href='{link}' target='_blank' class='blue-color'>{link}</a>. Allí encontrarás orientación paso a paso para mejorar tu comprensión y utilización de Ventrix Chatbot.",
  "username": "Nombre de usuario",
  "registrationWarning": "Advertencia de registro",
  "welcome": "Bienvenido",
  "registrationWarningText": "Has iniciado sesión correctamente, pero lamentablemente, tu cuenta aún no está registrada. Para seguir utilizando nuestra plataforma, por favor registra tu cuenta. ¡Es rápido y fácil! Simplemente haz clic en el botón 'Registrarse' a continuación e ingresa la información requerida. ¡Gracias por elegirnos! Si tienes alguna pregunta, por favor contacta a nuestro equipo de soporte.",
  "register": "Registrarse",
  "salesRepresentative": "Representante de ventas",
  "canAccessProfile": "Permitir acceso al gerente de ventas para asistencia e instalación",
  "canAccessProfileTip": "Estás permitiendo que tu curador o gerente de ventas tenga la capacidad de conectarse a tu negocio para detección de errores, optimización de ventas, mejora de conversiones, configuración de tus departamentos y chatbots. Puedes cancelar o denegar el acceso en cualquier momento.",
  "buyPackageTitle": "Paga y descarga tu paquete",
  "buyPackageDesc": "Si deseas cambiar tu paquete, simplemente regresa a la página anterior y realiza los ajustes necesarios. Todos los paquetes son descargables y están listos para su uso. Puedes cambiar tu paquete en cualquier momento dentro de la configuración de la descarga del escritorio SAAS.",
  "opportunities": "Oportunidades"
}
