{
  "terms": "Terms",
  "privacyPolicy": "Privacy Policy",
  "confirm": "Confirm",
  "validationField_required": "Fill out this field",
  "validationField_maxLength": "The maximum length allowed is {max}",
  "validationField_minLength": "This field should be at least {min} long",
  "validationField_maxValue": "The maximum value is {max}",
  "validationField_email": "Value is not a valid email address",
  "validationField_numeric": "Value must be numeric",
  "validationField_urlSlug": "Enter valid slug for url",
  "validationField_url": "The value is not a valid URL address",
  "validationField_alpha": "The value is not alphabetical",
  "validationField_alphaNum": "The value must be alpha-numeric",
  "validationError_exists": "This value for field {name} already exists",
  "validationError_somethingWrong": "This value for field {name} already exists",
  "serverError_login_expired": "This link has expired",
  "gotToSignIn": "Go to sign in",
  "chooseOptions": "-choose an option-",
  "enterPhoneNumber": "Enter phone number",
  "profileSettings": "Business Settings",
  "saveChanges": "Save Changes",
  "learnMore": "Learn More",
  "languages_en": "English",
  "languages_de": "German",
  "languages_es": "Spanish",
  "languages_fr": "French",
  "languages_ja": "Japanese",
  "languages_pt": "Portuguese",
  "languages_ru": "Russian",
  "languages_zh": "Chinese",
  "languages_hi": "Hindi",
  "profileName": "Business name",
  "uploadFilePlsWait": "Upload file, please wait",
  "browse": "browse",
  "chooseFileVideoOrImage": "Drag and drop an image or video",
  "fileSizeLimitation": "No more than {value} MB",
  "cover": "Cover",
  "toChooseFile": "to choose a file",
  "dragDropImage": "Drag and drop image",
  "toChooseImage": "to choose a image",
  "deleteCover": "Delete cover",
  "btnCreate": "Create",
  "doYouWantDelete": "Do you really want to delete?",
  "cancel": "Cancel",
  "or": "or",
  "delete": "Delete",
  "update": "Update",
  "btnUpdate": "Update",
  "dashboardTitle": "Dashboard",
  "productTitle": "Products",
  "noData": "No data",
  "categoryName": "Category name",
  "categoryEdit": "Edit catalog",
  "addNewProduct": "Add new product",
  "productCreate": "New product",
  "name": "Name",
  "price": "Price",
  "description": "Description",
  "mainPhoto": "Main photo",
  "currency": "Currency",
  "uploadAvatar": "Upload your avatar",
  "firstName": "First name",
  "lastName": "Last name",
  "nickname": "Nickname",
  "email": "Email",
  "chatBots": "Ventrix",
  "addNew": "Add new",
  "btnSave": "Save",
  "createOptions": "Create options",
  "editOptions": "Edit options",
  "btnAdd": "Add",
  "options": "Options",
  "ordersTitle": "Orders",
  "status": "Status",
  "action": "Actions",
  "provider": "Provider",
  "customer": "Customer",
  "orderId": "Order ID",
  "direction": "Direction",
  "pickupCode": "Pickup Code",
  "countItems": "Item Count",
  "telegramProvider": "Telegram",
  "whatsappProvider": "Whatsapp",
  "orderStatusPickedUp": "Picked Up",
  "orderStatusClosed": "Closed",
  "orderStatusPending": "Pending",
  "orderStatusDelivered": "Delivered",
  "orderStatusProcessing": "In Progress",
  "orderStatusCanceled": "Canceled",
  "reviews": "reviews",
  "pendingOrders": "Pending Orders",
  "totalDelivery": "Total Delivery",
  "ordersHold": "Orders Hold",
  "totalOrders": "Total Orders",
  "totalEarned": "Total Earned",
  "useDespaxoDrivers": "Use Ventrix Drivers",
  "useOurDrivers": "Use Our Own Drivers",
  "currentLocation": "Current location",
  "geoRadiusIncomingRequest": "GEO radius to incoming request",
  "managers": "Invites",
  "createManager": "Create invite",
  "registered": "Registered",
  "yes": "Yes",
  "no": "No",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "department": "Location",
  "faqTitle": "FAQ",
  "title": "Title",
  "editFaq": "Edit Faq",
  "createFaq": "Create Faq",
  "faqDelete": "Delete Faq",
  "add": "Add",
  "enterLocationName": "Enter location name",
  "representative": "Representative",
  "permissionsDenied": "Permission denied",
  "permissionCreateBusiness": "Your User package limits you to Ventrix usage. To activate more profiles, please upgrade to a more suitable package",
  "permissionCreateBusinessForPremium": "Your Ventrix package limits you to 1 business profile only. To add additional profiles, please upgrade to a more suitable package?",
  "info": "Info",
  "useDespaxoDriverInfo": "By selecting ‘use Ventrix Drivers’ you acknowledge that each delivered order will cost ${price} per 1km. As a Merchant, you can either agree to absorb this cost in your pricing by and checking the box \"Delivery Cost Included with Order\" or pass this delivery cost on to your customer by leaving unchecked.",
  "faqWarning": "Your Merchant FAQ’s must include the following question. As a Merchant, you should create your own FAQ’s or expand on these. The last thing a customer wants is to feel confused. If this happens, they will not buy your products. Please, make your FAQ’s as clear as possible with answers that you will guarantee.",
  "getAnswer": "Get answer",
  "faqDefaultQuestion": "Faq questions",
  "defaultFaqQuestion1": "What happens if my order does not arrive?",
  "defaultFaqQuestion2": "What happens if my order is late? More than an hour?",
  "defaultFaqQuestion3": "How do I cancel my order and what are the cancellation fees?",
  "defaultFaqQuestion4": "What is the latest time I can cancel without a charge?",
  "defaultFaqQuestion5": "What happens if my order is damaged on the way to me?",
  "defaultFaqQuestion6": "What happens if my delivery is not what I ordered?",
  "navigationStore": "Store",
  "count": "Count",
  "amount": "Amount",
  "storeDelete": "Delete store item",
  "createStore": "Create store item",
  "updateStore": "Update store item",
  "none": "None",
  "showProductOptions": "Show Product Options",
  "isRequired": "Required",
  "useCustomPrice": "Extra price",
  "btnAddNew": "Add new",
  "enterOptionTitle": "Enter option title",
  "createOptionsItem": "Add combo option item",
  "newTemplate": "New template",
  "editTemplate": "Edit template",
  "templateQuestion": "Question",
  "addConnections": "Add connection",
  "selectConnectionItem": "Select connection Item",
  "parentAnswer": "Parent connect",
  "templateType": "Template type",
  "templateName": "Template name",
  "templateText": "Text",
  "addNewTemplate": "Add new template",
  "answerHint": "Q/A have to be created before adding them here to connections. All answers create buttons so the shorter the answer, the better the chatbot looks.",
  "advancedOptions": "Advanced options",
  "show": "Show",
  "followSchedule": "Follow a schedule in advanced settings",
  "addNewFaq": "Add new FAQ",
  "isExtraPrice": "Use Extra Price",
  "demoMode": "Demo mode",
  "logoutDemoMode": "Logout from demo mode",
  "type": "Type",
  "parentConnection": "Parent connection",
  "message": "Message",
  "tags": "Tags",
  "enterTagName": "Enter tag name",
  "enterHashtagName": "Enter hashtag name",
  "search": "Search",
  "searchByHashtag": "Search by hashtag",
  "actionDisplayType": "Action display type",
  "faqGroupsTitle": "Groups FAQ",
  "faqDeleteGroup": "Delete group faq",
  "editFaqGroup": "Edit group faq",
  "createFaqGroup": "Create group faq",
  "whatsAppWarningMessage": "Messages and calls are end-to-end encrypted. No one outside of this chat, not even WhatsApp, can read or listen to them. Tap to learn more.",
  "unreadMessage": "UNREAD MESSAGE",
  "chooseCatalogs": "Choose catalogs",
  "chooseFaqGroups": "Choose FAQ groups",
  "menu": "Menu",
  "back": "Back",
  "orderDetail": "Order Detail",
  "total": "Total",
  "support": "Support",
  "role": "Role",
  "business": "Business",
  "selectRole": "Select Role",
  "chooseBusiness": "Choose business",
  "createAdmin": "Create admin",
  "updateAdmin": "Update admin",
  "adminDelete": "Delete admin",
  "managerDelete": "Delete manager",
  "addQA": "Add Q/A",
  "navigationManagerPanel": "Manager Panel",
  "text": "Text",
  "buttons": "Buttons",
  "dropdown": "Dropdown",
  "createTemplateDesc": "What you trying to do? Sell a product, create a support, create a schedule, choose a pre-made template to help you get started.",
  "availabilityReceiveOrders": "Availability to receive orders",
  "chicken": "Chicken",
  "burger": "Burger",
  "noKetchup": "No Ketchup",
  "selectSchedule": "Select schedule for showing catalog",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "faqGroup": "FAQ group",
  "items": "items",
  "potato": "Potato",
  "categoryLabel": "Category",
  "flowerShop": "Flower shop",
  "burgerHouse": "Burger house",
  "roundKilometers": "Kilometers",
  "navigation": {
    "together": "Together",
    "signIn": "Sign In",
    "navPackages": "Packages",
    "faq": "FAQ",
    "about": "About",
    "privateApp": "Private App"
  },
  "enterOtp": "Enter otp",
  "serverError_login_otp_unconfirmed": "Invalid otp code",
  "serverError_login_email": "Invalid email address",
  "enterCardData": "Enter card data",
  "payNow": "Pay now",
  "nickname_exists": "This nickname is already in use",
  "email_exists": "This email is already in use",
  "maxPaymentAmount": "Max payment amount must be no more :amount",
  "error_access_auth": "Authorization error",
  "walletTitle": "Wallet",
  "transaction": "Transaction",
  "walletSettings": "Wallet Settings",
  "receiveFunding": "Receive Funding",
  "sendFunding": "Send Funding",
  "addFunds": "Add Funds",
  "balance": "Balance",
  "walletTransactions": "Transactions",
  "payerAddress": "Payer address",
  "transferDate": "Transfer date",
  "noTransactions": "No transactions!",
  "transactions": "Transactions",
  "apiKey": "API Key",
  "apiSecret": "API Secret",
  "walletLabel": "When using your Credit Card / Debit Card for purchases or transactions you are required to enter your payment information. At no time will your card be charged until you have authorised and verified your purchase using your CVV code from your card",
  "walletLabel2": "NOTE: ALL CHARGES WILL APPEAR AS X GROUP ON YOUR STATEMENT.",
  "saveDebitCard": "Save debit card",
  "navSettings": "Settings",
  "walletReceive": "Receive Funding",
  "copy": "Copy",
  "account": "ACCOUNT",
  "formFirstName": "First Name",
  "formLastName": "Last Name",
  "formNickName": "Username",
  "walletNumber": "Wallet Number",
  "chooseOption": "Choose option",
  "descriptionOptional": "Description (optional)",
  "buttonSend": "Send",
  "user": "User",
  "walletAddFund": "Quick Fund",
  "walletSendFunding": "Send Funding",
  "funding": "Funding",
  "serviceCharge": "Service Charge",
  "charge": "Charge",
  "selectAmount": "Select amount",
  "btnClose": "Close",
  "fromAddress": "From address",
  "toAddress": "To address",
  "transactionDetail": "Transaction detail",
  "return": "Return",
  "menuItem": "Menu item",
  "orderNotes": "Notes. Please deliver to back of the residence Door 32A",
  "returnTo": "Return to",
  "faqGroups": "FAQ groups",
  "backToList": "Back to list",
  "spagetti": "Spaghetti",
  "tomYum": "Tom yum",
  "pizza": "Pizza",
  "salad": "Salad",
  "chickenWing": "Chicken wings",
  "templateFakeOrder_default": "Your order: <br>Item 1 - 10 (USD)<br>Item 2 - 8 (USD)<br>Item 3 - 5 (USD)<br><br>10:20 - orders is accepted<br>10:25 - order cook<br>10:40 - delivery service<br><br>",
  "templateFakeOrder_44": "Your order: <br>PORSCHE MACAN TURBO M.2018 - 185000 (USD)<br><br>10:00 - orders is accepted<br>11:40 - delivery service<br><br>",
  "templateFakeOrder_48": "Your order: <br>Perk Up Dry Shampoo - 80 (GTQ)<br>Heavy Duty Toenail Clippers - 69 (GTQ)<br><br>10:10 - orders is accepted<br>10:40 - delivery service<br><br>",
  "templateFakeOrder_47": "Your order: <br>Pear Cut - 7900 (EUR)<br>Round Twisted - 5025 (EUR)<br><br>10:03 - orders is accepted<br>10:55 - delivery service<br><br>",
  "templateFakeOrder_46": "Your order: <br>Bonpas Cotes Du Rhone 750ml - 122 (BRL)<br>Costilla de Res LB - 34.99 (BRL)<br><br>10:40 - orders is accepted<br>11:30 - delivery service<br><br>",
  "templateFakeOrder_45": "Your order: <br>ASPIRINA FORTE 500MG/65MG - 101.6 (JPY)<br>VIROGRIP AM - 35 (JPY)<br><br>10:44 - orders is accepted<br>11:32 - delivery service<br><br>",
  "templateFakeOrder_43": "Your order: <br>Mother's Day Special - 700 (GTQ)<br>Pink Roses & Chocolates - 600 (GTQ)<br><br>10:44 - orders is accepted<br>11:32 - delivery service<br><br>",
  "templateFakeOrder_42": "Your order: <br>Margherita - 10 (USD)<br>Regina - 20 (USD)<br><br>9:50 - orders is accepted<br>10:23 - delivery service<br><br>",
  "templateFakeOrder_394": "Your order: <br>Apple iPhone 13 Pro Max - 1599 (USD)<br>Green iPhone 13 Case - 18 (USD)<br><br>10:10 - orders is accepted<br>10:30 - delivery service<br><br>",
  "templateFakeOrder_395": "Your order: <br>Empanada de Queso Crema - 10 (GTQ)<br>Strudel de Manzana 5 uni - 40 (GTQ)<br>Chapatona - 11 (GTQ)<br><br>10:10 - orders is accepted<br>11:00 - delivery service<br><br>",
  "templateFakeOrder_397": "Your order: <br>Fruit Monster Mixed Berries - 190 (GTQ)<br>BGeekvape Aegis Boost Pro 100W - 440 (GTQ)<br><br>10:20 - orders is accepted<br>10:30 - delivery service<br><br>",
  "templateFakeOrder_401": "Your order: <br>Cyber Secure ISP and VPN Services - 499 (GTQ)<br>Smart Door Lock-Classic - 179 (GTQ)<br><br>10:23 - orders is accepted<br>10:30 - delivery service<br><br>",
  "templateFakeOrder_399": "Your order: <br>Cellucor C4 Ripped Pre-Workout - 45 (USD)<br>Boxing Classes - 75 (USD)<br><br>10:50 - orders is accepted<br>11:05 - delivery service<br><br>",
  "templateFakeOrder_400": "Your order: <br>Item 1<br>Item 2<br>Item 3 <br><br>10:20 - orders is accepted<br>10:25 - order cook<br>10:40 - delivery service<br><br>",
  "navigationDeleteDemoAccount": "Delete demo account",
  "mobilePlugText": "Ventrix panel only accessible using a tablet or computer",
  "quantityLimits": "Quantity Limits",
  "tooltipForQuantityLimits": "Limit the \"choose\" option quantity of your products available. For example: (Option Title: \"Toppings\" ) (\"Quantity Limits\") : \"2\" your options could be Ketchup, Mayonnaise, Relish, Mustard.",
  "labelVerifyBridge": "Connect bot",
  "buttonConnect": "Connect",
  "disconnect": "Disconnect",
  "scanQRFromWA": "Scan QR code from Whatsapp",
  "buttonClose": "Close",
  "connections": "Connections",
  "connectionManager": "Connection Manager",
  "connectToWa": "Connect to Whatsapp",
  "connectToTg": "Connect to Telegram",
  "connectionProviderTitle": "Connection messengers",
  "confirmPhoneNumber": "Confirm your phone number",
  "enterCodeFromTelegram": "Enter code from Telegram",
  "enterCode": "Enter code",
  "enterPasswordFromTelegram": "Enter password from Telegram",
  "weOfferPickup": "We Offer Pickup",
  "deliverySettings": "Delivery settings",
  "deliverySettingsDesc": "Select the types of delivery that will be available to users",
  "freeDeliveryHintForFixedDistance": "After {distance} km of free delivery, delivery will cost ${amount}",
  "freeDeliveryHintForPerKm": "After {distance} km of free delivery, delivery will cost ${amount} per 1 km",
  "generalManager": "General Manager",
  "freeDeliveryRadius": "Free Delivery Radius",
  "hintForPayDelivery": "Set the price you will charge if the free delivery exceeds your radius.",
  "fixedDeliveryPrice": "Fixed Delivery Price",
  "pricePerKm": "Price per KM",
  "navigationPersonnel": "Personnel",
  "all": "All",
  "create": "Create",
  "freeDelivery": "Free delivery",
  "businessCoverDelivery": "Shared Delivery Cost",
  "businessCoverDeliveryInfo": "Add the percentage you are willing to pay towards delivery cost. Customer will be billed the percentage difference.",
  "deliveryPayClient": "Customer Pays Full Delivery Cost",
  "deliveryPayClientInfo": "When selected, Customer pays all delivery costs.",
  "freeDespaxoDelivery": "Offer Free Ventrix Delivery",
  "freeDespaxoDeliveryInfo": "When offering free delivery, delivery cost will be deducted from Vendor Payout",
  "selectLocationOnMap": "Select your location on map",
  "from": "From",
  "to": "To",
  "fillOutSchedulePickup": "Fill out the pickup schedule",
  "selectTemplate": "Select Template",
  "paymentSettings": "Payment Settings",
  "paymentSettingsDesc": "Select the types of payments that will be available to users",
  "xprAccountAddress": "XPR account address",
  "useOnline": "Use Stripe",
  "useXPR": "Use XPR",
  "save": "Save",
  "useMia": "Use MIA Vivid",
  "validationError_required": "Fill out {name} field ",
  "additionalOptions": "Additional options",
  "useMiaHint": "Use MIA Vivid artificial intelligence in your chatbot to assist customers in choosing products, placing orders, asking questions, etc. in an interactive-dialogue form. All functions and connections of the chatbot in the template will be converted into an interactive dialogue with the client.",
  "payment_status_pending": "Pending",
  "payment_status_confirmed": "Confirmed",
  "payment_status_rejected": "Rejected",
  "payment_status_cancelled": "Cancelled",
  "payment_status_refunded": "Refunded",
  "payment_status_user_ready_to_pay": "User ready to pay",
  "orderStatus": "Order status",
  "orderStatusInProcessing": "In Processing",
  "pickUp": "Pick Up",
  "address": "Address",
  "inviteStatus": "Invite status",
  "invitePending": "Pending",
  "inviteAccept": "Accepted",
  "resendInvite": "Resend Invite",
  "successResendInvite": "Invite was successfully sent again",
  "successCopyToClipboard": "Success copied to clipboard",
  "walletAddress": "Wallet address",
  "addWalletAddress": "Add wallet address",
  "walletAddressError": "An error occurred while creating the location. The business owner has not created a wallet, let them know, or enter the wallet address yourself:",
  "dontHavePermissionsForDepartment": "You do not have access to the location yet, wait for the administrator to add you to the location",
  "chatBotUsername": "Ventrix ChatBot username",
  "validationField_username": "Enter valid username",
  "warning": "Warning",
  "continue": "Continue",
  "createNewMatrixAccountWarning": "You have been assigned the role of connection-manager. For the operation of the ventrix chatbots, a logout will be performed from your personal messenger accounts. You should re-login to your messengers to initiate the ventrix chatbot",
  "selectBusiness": "Select Business",
  "validationError_cannotAddYourself": "You cannot add yourself here",
  "validationError_managersAlreadyExist": "This email already exist in system",
  "markers": "Markers",
  "cantCreateMoreLocations": "You cant add more locations",
  "createNewDepartments": "Create new location",
  "locations": "Locations",
  "locationAlreadyExist": "This location already exist",
  "enoughLocationCount": "Select at least one location on the map",
  "businessLevelSmall": "Small",
  "businessLevelMedium": "Medium",
  "businessLevelBig": "Big",
  "businessLevelSmallOption_1": "1 department",
  "businessLevelSmallOption_2": "10 outlets per departments",
  "businessLevelMediumOption_1": "10 department",
  "businessLevelMediumOption_2": "Unlimited number of outlets per department",
  "businessLevelBigOption_1": "Unlimited number of department",
  "businessLevelBigOption_2": "Unlimited number of outlets per department",
  "businessLevelOption_3": "24/7 bot *",
  "informationForLevel3": "this option is available only for paid packages, such as (Premium, Premium+, Enterprise)",
  "chooseOptionType": "Choose options type:",
  "comboOption": "Combo",
  "extraOption": "Extra",
  "serverError_xpr_address_invalid_address": "xpr address must be a hola wallet address",
  "serverError_xpr_address_required": "Fill out this field",
  "createConnectionManager": "Create Connections",
  "haveWAConnection": "You already have WhatsApp connection",
  "haveTGConnection": "You already have Telegram connection",
  "language": "Language",
  "fixedCurrency": "Fixed Currency",
  "currencyRate": "Currency Rate",
  "myWorkspace": "My Workspace",
  "workspace": "Workspace",
  "navigationMessages": "Messages",
  "comingSoon": "Coming Soon",
  "userContacts": "User Contacts",
  "saveContact": "Save contact",
  "businessTypeSmallListItem1": "One Active Business Profile",
  "businessTypeSmallListItem2": "Unlimited Active FAQ’s, Schedulers, and Catalogs",
  "businessTypeSmallListItem3": "One 15km Delivery Area",
  "businessTypeSmallListItem4": "10 Outlet Locations per 15km",
  "businessTypeSmallListItem5": "10 Admin Users",
  "businessTypeSmallListItem6": "Card Processing 10% with Direct Deposit",
  "businessTypeSmallListItem7": "Doesn’t have access to white label services",
  "businessTypeMediumListItem1": "Unlimited Active Business Profile",
  "businessTypeMediumListItem2": "Unlimited Active FAQ’s, Schedulers, and Catalogs",
  "businessTypeMediumListItem3": "Ten 15KM Area",
  "businessTypeMediumListItem4": "Unlimited Outlet Locations per 15km",
  "businessTypeMediumListItem5": "Real-Time Live Chat Panel",
  "businessTypeMediumListItem6": "Unlimited Admin users",
  "businessTypeMediumListItem7": "Card Processing 6.8% with Direct Deposit",
  "businessTypeMediumListItem8": "Doesn’t have access to white label service",
  "businessTypeBigListItem1": "Unlimited Active Business Profile",
  "businessTypeBigListItem2": "Unlimited Active FAQs Calendars and Catalogs",
  "businessTypeBigListItem3": "Unlimited 15KM Area",
  "businessTypeBigListItem4": "Global Shipping",
  "businessTypeBigListItem5": "Unlimited Locations per 15km",
  "businessTypeBigListItem6": "Real-Time Live Chat Panel",
  "businessTypeBigListItem7": "Unlimited Admin Users",
  "businessTypeBigListItem8": "White Label Personal Domain redirect and Branding",
  "businessTypeBigListItem9": "Card Processing 4.5% Direct Deposit",
  "businessTypeBigListItem10": "Has full access to white label services",
  "transactionId": "Transaction Id",
  "transactionHash": "Transaction Hash",
  "subtotal": "Subtotal",
  "deliveryFee": "Delivery fee",
  "paymentStatus": "Payment status",
  "senderInfo": "Sender info",
  "fullName": "Full name",
  "userName": "Username",
  "schedule": "Schedule",
  "scheduleSettings": "Schedule Settings",
  "aroundClock": "24 hours a day",
  "order": "Order",
  "host": "Host",
  "deliveryMethod": "Delivery method",
  "customerComment": "Customer comment",
  "transactionNumber": "Transaction number",
  "courier": "Courier",
  "pickUpAddress": "Pick Up address",
  "deliveryAddress": "Delivery address",
  "thankYouComeAgain": "Thank you, come again soon!",
  "upgradeYourPackage": "Upgrade your package",
  "upgrade": "Upgrade",
  "upgradePackageText1": "Unfortunately, your current subscription package is not suitable for this service. Please check your subscription and update it if you want to access this service",
  "upgradePackageText2": "You have exceeded the limits of your current package, and it is recommended to consider upgrading it.",
  "break": "Break",
  "workingHours": "Working hours",
  "packageMoreDetailLink": "You can see other package options",
  "upgradePackageText3": "You don't have a subscription for this type of business, but you can purchase one if you choose and buy the relevant package",
  "here": "here",
  "successUpdate": "Success update",
  "successCreate": "Success create",
  "successDelete": "Success delete",
  "noResultFound": "No result found",
  "enterWorkspaceName": "Enter workspace name",
  "businessTypeProduct": "Product",
  "businessTypeService": "Service",
  "appointmentModeOrderForm": "Order Form",
  "appointmentModeOnline": "Online check-in",
  "appointmentModeCalendar": "Calendar, appointments, schedules",
  "customerAppointmentMode": "Customer appointment mode",
  "onSiteService": "On-site service",
  "serviceRoles": "Service Roles",
  "serviceRoleName": "Service role name",
  "roleDelete": "Delete Role",
  "profession": "Profession",
  "professions": "Professions",
  "phone": "Phone",
  "skills": "Skills",
  "skill": "Skill",
  "duration": "Duration",
  "durationMin": "Duration, min",
  "actualExecutionTime": "Actual Execution Time",
  "executionTime": "Execution Time",
  "measure": "Measure",
  "services": "Services",
  "service": "Service",
  "category": "Category",
  "updateService": "Update service",
  "addService": "Add service",
  "workSchedule": "Work Schedule",
  "sector": "Sector",
  "productName": "Product name",
  "extraOptions": "Extra options",
  "printReceipt": "Print Receipt",
  "progress": "Progress",
  "cannotProcess": "Cannot Process",
  "reason": "Reason",
  "internalNotes": "Internal notes",
  "expectedResolutionDate": "Expected resolution date",
  "followUpRequired": "Follow up required",
  "assignedTo": "Assigned to",
  "priority": "Priority",
  "customerPreference": "Customer preference",
  "preemptiveRefusal": "Preemptive refusal",
  "deliveredOrPerformed": "Delivered or performed",
  "modified": "Modified",
  "cancelled": "Cancelled",
  "addNewAdditionalOptions": "Add new additional options",
  "products": "Products",
  "rating": "Rating",
  "deliveryMode": "Delivery Mode",
  "preDeliveryStatus": "Pre Delivery status",
  "deliveryStatus": "Delivery status",
  "postDeliveryStatus": "Post Delivery status",
  "deliveryShipmentStatus": "Delivery shipment status",
  "driverNotificationStatus": "Driver notification status",
  "customerNotificationResponse": "Customer notification response",
  "progressCustomerStatus": "Progress customer status",
  "disputeStatus": "Dispute status",
  "detail": "Detail",
  "close": "Close",
  "successAdded": "Success added",
  "successReplaced": "Success replaced",
  "subsProducts": "Substitution products",
  "oldProducts": "Old products",
  "assetReturnTime": "Asset Return Time",
  "assetReleaseTime": "Asset Release Time",
  "selectTime": "-select time-",
  "assignedStaffFeedback": "Assigned Staff Feedback",
  "assetStatus": "Asset status",
  "driver": "Driver",
  "assetCondition": "Asset condition",
  "packagingType": "Packaging type",
  "expectedCompletionTime": "Expected Completion Time",
  "assetItemsStatus": "Asset Items Status",
  "assetDocumentsInspectionStatus": "Asset Documents Inspection Status",
  "immediateActions": "Immediate Actions",
  "probableCause": "Probable Cause",
  "followUpDate": "Follow Up Date",
  "evidenceAttachments": "Evidence Attachments",
  "clientCommunicationStatus": "Client Communication Status",
  "resolutionStatus": "Resolution Status",
  "escalationNeeded": "Escalation Needed",
  "longTermSolutionsProposed": "Long Term Solutions Proposed",
  "clientFeedback": "Client Feedback",
  "causeIdentified": "Cause Identified",
  "severityLevel": "Severity Level",
  "relatedAssets": "Related Assets",
  "reportedBy": "Reported By",
  "affectedParties": "Affected Parties",
  "resolutionDescription": "Resolution Description",
  "new": "New",
  "inProgress": "In Progress",
  "problem": "Problem",
  "completed": "Completed",
  "notificationBusinessText": "You have {length} new orders in business {name}",
  "replace": "Replace",
  "selected": "Selected",
  "dispute": "Dispute",
  "clientVerificationStatus": "Client Verification Status",
  "clientFeedbackDate": "Client Feedback Date",
  "mediationRequired": "Mediation Required",
  "merchantResponseDate": "Merchant Response Date",
  "raisedBy": "Raised by",
  "resolutionAttempts": "Resolution Attempts",
  "resolutionProposedByDespaxo": "Resolution proposed by Ventrix",
  "sellerResponse": "Seller Response",
  "finalResolution": "Final Resolution",
  "acceptedResolution": "Accepted Resolution",
  "validationField_requiredTrue": "Please check this field",
  "orderStatuses": "Order statuses",
  "preDeliveryStatuses": "Pre Delivery statuses",
  "deliveryStatuses": "Delivery statuses",
  "edit": "Edit",
  "uploadMultipleFileHint": "Items require a minimum of (1) main image - (6 images max) 150px x 150px. Your first uploaded image will be the primary image first seen by customers viewing your items.",
  "enterProductName": "Enter product name",
  "selectProduct": "Select product",
  "createdAt": "Created at",
  "orderAmount": "Amount",
  "orderAppealStatus": "Appeal status",
  "orderModerationStatus": "Moderation status",
  "departureTime": "Departure Time",
  "estimatedArrivalTime": "Estimated Arrival Time",
  "itemConditions": "Conditions",
  "pickupConfirmation": "Pickup Confirmation",
  "pickupTime": "Pickup Time",
  "expectedDeliveryTime": "Expected Delivery Time",
  "trafficConditions": "Traffic Conditions",
  "waitingStartTime": "Waiting Start Time",
  "contactsAttempts": "Contacts Attempts",
  "deliveryTime": "Delivery Time",
  "code": "Code",
  "recipientName": "Recipient Name",
  "deliveryConfirmation": "Delivery Confirmation",
  "conditionOnDelivery": "Condition On Delivery",
  "clientNotification": "Client Notification",
  "newEstimatedDeliveryTime": "New Estimated Delivery Time",
  "estimatedDelayMinutes": "Estimated Delay Minutes",
  "incidentDescription": "Incident Description",
  "timeOfIncident": "Time of Incident",
  "reschedulingPlan": "Rescheduling Plan",
  "impactOnDelivery": "Impact On Delivery",
  "immediateActionsTaken": "Immediate Actions Taken",
  "problemReportTime": "Problem Report Time",
  "shouldDelivery": "Delivery Time",
  "mapBusinessHint": "Select the location of your business",
  "selectDriverLocation": "Select location driver",
  "selectNewLocationDestination": "Select a new destination location",
  "visibleName": "Visible Name",
  "maxFutureReservationLimitHour": "Max future reservation limit hour",
  "employee": "Employee",
  "assets": "Assets",
  "asset": "Asset",
  "internalId": "Internal id",
  "breakTime": "Break time",
  "breakTimeUnit": "Break time unit",
  "sessionOrServiceDurationTime": "Session or service duration time",
  "sessionOrServiceUnit": "Session or service unit",
  "isActive": "Active",
  "minute": "Minute",
  "hour": "Hour",
  "day": "Day",
  "unit": "Unit",
  "minUnitReservation": "Min reservation time",
  "maxUnitReservation": "Max reservation time",
  "problemDescription": "Problem Description",
  "proposedResolution": "Proposed Resolution",
  "totalOrderPrice": "Order price",
  "weOfferGlobalDelivery": "We offer Global Delivery",
  "global": "Global",
  "own": "Own",
  "pickup": "Pickup",
  "despaxo": "Ventrix",
  "orderDeliveryTime": "Order delivery time",
  "eventEndTime": "Event end time",
  "eventStartTime": "Event start time",
  "trackingNumber": "Tracking Number",
  "courierServices": "Courier Services",
  "globalDelivery": "Global Delivery",
  "resourceTypeHuman": "Is Human Resource",
  "appointments": "Appointments",
  "startTime": "Start Time",
  "endTime": "End Time",
  "validationField_integer": "Please enter an integer number",
  "scheduler": "Scheduler",
  "catalogServices": "Catalog Services",
  "companyIdField": "Company ID (i.e. SKU or Product Number)",
  "employerServicesTitle": "Select the services that the employee will perform",
  "downtime": "Downtime",
  "timeUnit": "Time Unit",
  "nameSchedule": "Name Schedule",
  "dayLower": "day",
  "daysLower": "days",
  "scheduleName": "Schedule Name",
  "addNewSchedule": "Add Employee Schedule",
  "eventInfo": "Event Info",
  "reservationLimit": "Reservation Limit",
  "reservationLimitHint": "This signifies how far in advance customers can schedule time with this persons schedule.",
  "markAsProduct": "Classify as Product",
  "hintForAssetImages": "",
  "setRangeForService": "Set Range for Service",
  "descForRangeService": "This option allows users to choose the date of the service within a specific range of days (from and to, inclusive). It can be beneficial for various services such as car rentals (from and to) or hotel bookings (from and to), among others.",
  "comment": "Comment",
  "employers": "Employees",
  "employer": "Employee",
  "employerImages": "Employee images",
  "refund": "Refund",
  "refunded": "Refunded",
  "complete": "Complete",
  "appointment": "Appointment",
  "reply": "Reply",
  "addReply": "Add reply",
  "editReply": "Edit reply",
  "enterReplyForReview": "Enter your reply to the review here...",
  "yourSavedCard": "Your saved card",
  "dateAdded": "Date added",
  "useNewCard": "Use new card",
  "useSavedCard": "Use saved card",
  "selectCard": "Select card",
  "purchaseFund500": "An error occurred during payment. Please try to make the payment again later",
  "fee": "Fee",
  "validationField_not_exists": "User with this nickname not found",
  "validationField_minValue": "The minimum value is {min}",
  "validationTransfer_not_exists": "During the money transfer, an issue arose due to the absence of a created electronic wallet for the recipient.",
  "transferFunds500": "During the funds transfer process, an unexpected situation arose, which led to the inability to complete the transaction at the moment",
  "appointmentAdd": "Add Appointment",
  "serviceLocation": "Service Location",
  "selectOptions": "Options",
  "selectService": "Select service",
  "placeholderSelectService": "Enter service name",
  "fieldGeolocationTooltip": "Choose a radius for your business where customers can place orders",
  "fieldParentConnectionTooltip": "Node after selecting which the option to switch to the current template will be located",
  "fieldChooseCatalogTooltip": "Select categories of products or services that will be available to customers on the external website",
  "fieldDepartmentTemplateIdTooltip": "Select a template for how your bot will respond to customers. Don't forget to add catalogs and FAQs to the template that will be available to users during their conversation with the bot. If you don't have templates, you need to create them in the 'Templates' section.",
  "fieldChooseFaqTooltip": "Select FAQ groups that will be available to users on the external website",
  "chatsDepartment": "Chat",
  "searchByChat": "Search by chat",
  "provideLiveSupport": "This business offers real-time/live support",
  "coverPhoneAllDepartments": "One phone number will cover all departments",
  "supportPhone": "Live support",
  "supportPhoneTooltip": "This field contains information about the support phone number that will be used in the chatbot as the primary means of communication with the support manager. It is intended to provide users with the ability to seek assistance or consultation if needed",
  "selectedDate": "Selected Date",
  "currentAppointmentInfo": "Current appointment information",
  "editAppointment": "Edit Appointment",
  "despaxoDeliveryDoesntWorkForRegionWarning": "Unfortunately, delivery is currently unavailable in the selected region. We plan to expand our coverage in the future to enable delivery to this area.",
  "logo": "Logo",
  "answer": "Answer",
  "question": "Question",
  "signUp": "Sign up",
  "login": "Login",
  "salesRep": "Sales rep",
  "package": "Package",
  "loginTitle": "Ventrix Admin Login",
  "loginSubtitle": "Sign in with your email. An authorization code will be sent to your email. Make sure your email is correct and check your SPAM folder if you don't receive it within a minute.",
  "signIn": "Sign in",
  "dontHaveAcc": "You do not have an account?",
  "alreadyHaveAcc": "Already have an account?",
  "registrationTitle": "Sign up and Download for FREE!",
  "registrationSubtitle": "Always enjoy the Basic FREE version - simply register and download to get started.",
  "submit": "Submit",
  "buy": "Buy",
  "acceptAgreementShort": "I agree to the all",
  "privacyPolicies": "Privacy Policies",
  "and": "and",
  "profile": "Profile",
  "packages": "Packages",
  "avatar": "Avatar",
  "upgradePackage": "Upgrade Package",
  "yourPackage": "Your package",
  "saasSettings": "SaaS Settings",
  "saasWarningText": "Your current {package} package allow for {size} stored documentation and images files. To get more data please upgrade your package",
  "used": "used",
  "free": "Free",
  "storage": "Storage",
  "whiteLabel": "White label",
  "externalLogo": "External logo",
  "newVersionAvailable": "New version available",
  "admin": "Admin Controls",
  "navigationHome": "Dashboard",
  "navigationAdmins": "Site Administrators",
  "personnel": "Site Users",
  "setup": "Template Configurations",
  "navigationCatalog": "Product Templates",
  "navigationFaq": "FAQ Templates",
  "navigationScheduler": "Schedule Templates",
  "workingSchedules": "Working Hours",
  "navigationTemplates": "Bot Templates",
  "messages": "Message Center",
  "navigationFeedbacks": "Comments",
  "navigationReviews": "Ratings",
  "orders": "ALL Orders",
  "navigationOrders": "Orders",
  "navigationLogout": "Logout",
  "offline": "Offline",
  "online": "Online",
  "waConnection": "Whatsapp connection",
  "tgConnection": "Telegram connection",
  "locationCounts": "Location counts",
  "botStatus": "Bot status",
  "departments": "Branches",
  "departmentLabel": "Branch",
  "navigationProfiles": "All Profiles",
  "bookings": "Bookings",
  "accounting": "Accounting",
  "dataProcessingAgreement": "Data Processing Agreement",
  "termsAndConditions": "Terms & Conditions",
  "accept": "Accept",
  "usernameAlreadyExist": "This username already taken",
  "send": "Send",
  "serverError_login_auth": "Login auth error",
  "today": "Today",
  "yesterday": "Yesterday",
  "privateAndSecure": "Private & Secure",
  "privateAndSecureText1": "Ventrix AI advocates for Privacy as an essential human right, especially in today's world. To uphold this belief, we have built our own tech infrastructure, including API's, to safeguard our data and user information. We are committed to maintaining a neutral stance on political and religious views. Therefore, we consider third-party API's, particularly those from corporate privacy violators selling data, as unsafe and unsuitable for our platform. Consequently, we prioritize building our own software concepts and implementing automation to minimize human errors.",
  "privateAndSecureText2": "At Ventrix, we firmly believe that nothing comes without a cost, and we always follow through on our promises. Privacy and security are at the core of our values. To ensure maximum user anonymity and protection, we allow registration without disclosing personal details or names, although an active email address is required (which can also be anonymous). Your chosen username serves as the primary means of connecting profiles within our system. ",
  "privateAndSecureText3": "To fortify our security measures, we leverage a combination of premium tools and our proprietary locks and keys. Our integration includes websockets, DTLS (Datagram Transport Layer Security), and UDP (User Datagram Protocol). We employ MITM-proxy (Man-in-the-Middle) to shield against external vulnerabilities, including those within the Ventrix user Registration system. We utilize HTTPS and WSS (WebSockets) to enforce permissions that align with our system requirements. ",
  "privateAndSecureText4": "At Ventrix, we prioritize secure entry points to guarantee the utmost security for our users.",
  "finishRegistration": "Finish Registration",
  "finishRegistrationSubText": "Let’s get you all st up so you can access your personal account.",
  "checkIn": "Check in",
  "loginBlockTitle": "Engage your chat app customers instantly using AI bot",
  "loginBlockText1": "With Ventrix, improve and amplify your brand growth with automated conversations on popular messaging platforms such as WhatsApp, Telegram, Instagram and Facebook Messenger, among others. Centralize all platforms in one place with no API cost.",
  "loginBlockText2": "Drive instant online sales with catalogs, FAQs, scheduling, customer engagement, and lead generation by implementing automated conversations using templates and scatter responses.",
  "loginBlockText3": "Effortlessly deliver personalized experiences, instant support, and automated interactions to maximize your brand's success and facilitate seamless online transactions.",
  "loginBlockText4": "If you need a demo of our platform or have any questions, our team of advisors are available to assist you. No friend in communicating with us",
  "domainSettings": "Domain settings",
  "whiteLabelNotAvailableWarning": "Your current package does not include this option. If you wish to get it, upgrade your package.",
  "downgradeInfo": "Your {currentPackage} package expired in {expired}, and will automatically revert to Basic",
  "yourDomain": "Your sub-domain slug",
  "previewYourSiteUrl": "Preview your site url",
  "changeDomain": "Change domain",
  "domainSlug": "Domain Slug",
  "maxLimitAdminsForPackage": "Maximum number of admins you can create is <strong>{count}</strong> to increase this limit upgrade your package",
  "license": "License",
  "expired": "Expired",
  "packageOptions": "Package options",
  "addOnPackages": "Add on - Packages",
  "packageInfo": "Package info",
  "licenseOwner": "License owner",
  "supportPackageName": "Support",
  "supportPackageDesc": "Receive real-time live screen support for one year with your SAAS products.",
  "devPackageName": "Development",
  "devPackageDesc": "Our team will set up and configure your identity for launch. Provide us with your content, including a maximum of 100 products, FAQ's, and schedules, and we will handle the rest. All you have to do is connect your team.",
  "contacts": "Contacts",
  "trainingDocumentation": "Training Documentation",
  "salesAssociate": "Sales associate",
  "salesManager": "Sales manager",
  "lastUpdates": "Updates",
  "verifyYourEmailTitle": "Verify your Email",
  "verifyYourEmailDesc": "Didnt get an email with your Code? Check your SPAM folder or start over by entering a valid email.",
  "introBusinessEmptyPageText": "Congratulations on registering for Ventrix AI Chatbot! As you embark on your journey to build your chatbot, we understand that getting started can sometimes be confusing. But don't worry, just like riding a bike for the first time, it may seem challenging at first, but once you learn, you'll be using it with ease.<br><br>To provide you with seamless and personalized experiences, we offer paid online support. Additionally, if you prefer a hassle-free experience, we can develop your entire chatbot system for you, including less than 100 products, for a small fee and a quick turnaround time. Think of it as getting a bike with training wheels. If you're interested in upgrading your package or adding full development, please contact your sales representative, and they will assist you in making the necessary arrangements.<br><br>If you'd like to get started on your own, begin by adding your first profile. You can do this by clicking the \"Add New\" button located in the top right corner of the screen. Once you've created your profile, you can access it to open the profile's menu options. Designed to make the process easier for you, each perspective link starts with a help screen and includes rollover samples or comments to guide you in building your first chatbot.<br><br>To further assist you, we also provide training videos on our YouTube channel at <a href='{link}' target='_blank' class='blue-color'>{link}</a>. These videos will give you a visual walkthrough and help you navigate through the various features and functionalities of Ventrix AI Chatbot.",
  "introAnyEmptyPageText": "To begin your journey with Ventrix Chatbot software, simply click on the \"Add New\" link located in the top right corner. If you require comprehensive video instructions on how to make the most of the software, we invite you to visit our YouTube training section at <a href='{link}' target='_blank' class='blue-color'>{link}</a>. There, you will find step-by-step guidance to enhance your understanding and utilization of Ventrix Chatbot.",
  "downloadOurApp": "Download our app",
  "confirmOtpTitle": "Verify your Email",
  "confirmOtpDesc": "Didnt get an email with your Code? Check your SPAM folder or start over by entering a valid email.",
  "signInText": "Dear user,<br><br>Ventrix is an SaaS desktop/tablet application, although we allow registrations via website, Unfortunately, we do not support a web version at this time. We invite you to download the desktop application where you can log in after registering on the web.<br><br>Please follow the link below to download the desktop application that best suits your operating system<br><br>Thank you for your understanding and choosing our platform.<br><br>Best regards, Ventrix",
  "successRegisterTitle": "Congratulations on successfully registering!",
  "successRegisterWithoutSalesLink": "Dear user,<br><br>Thank you for registering with Ventrix. While we do offer website registrations, we want to inform you that Ventrix is an SaaS desktop/tablet application, and it is available exclusively through distributor resellers.<br><br>To ensure a smooth experience for you, we kindly ask that you email <a class='blue-color' href='mailto:{email}'>{email}</a>. Our team will connect you with a local distributor who can assist you further. Please allow us 24 hours to make the necessary arrangements. We apologize for any inconvenience this may cause.<br><br>Thank you again for choosing our platform. We appreciate your understanding.<br><br>Best regards, Ventrix",
  "successRegisterWithSalesLink": "We're delighted to welcome you to our community. You now have access to all our features and opportunities.<br><br>To continue your journey, we recommend downloading our app. This will allow you to enjoy all the benefits of our service directly on your device. With the app, you can conveniently use our services anytime, anywhere.<br><br>Don't hesitate! Download the app now and join us on an exciting and engaging adventure.<br><br>Thank you for choosing us!",
  "signUpText": "Thank you for your Interest in Ventrix. While we do offer website registrations, we want to inform you that Ventrix is an SaaS desktop/tablet application, and it is available exclusively through distributor resellers.<br><br>To ensure a smooth experience for you, we kindly ask that you email <a class='blue-color' href='mailto:{email}'>{email}</a>. Our team will connect you with a local distributor who can assist you further. Please allow us 24 hours to make the necessary arrangements. We apologize for any inconvenience this may cause.<br><br>Thank you again for choosing our platform. We appreciate your understanding.",
  "downloadFor": "Download For",
  "congratulations": "Congratulations",
  "inviteSuccessText": "Your invite has been successfully confirmed. You are now part of our business N as role R. Thank you for joining our team. Wishing you success in your new role!",
  "invite": "Invite",
  "username": "Username",
  "registrationWarning": "Registration Warning",
  "welcome": "Welcome",
  "registrationWarningText": "You've successfully logged in, but unfortunately, your account isn't registered yet. To continue using our platform, please register your account. It's quick and easy! Simply click the 'Register' button below and enter the required information. Thank you for choosing us! If you have any questions, please contact our support team.",
  "register": "Register",
  "salesRepresentative": "Sales Representative",
  "canAccessProfile": "Allow access to the sales manager for assistance and setup",
  "canAccessProfileTip": "You are allowing your curator or sales manager to have the ability to connect to your business for error detection, sales optimization, conversion improvement, setting up your departments and chatbots - you can cancel or deny access at any time.",
  "buyPackageTitle": "Pay and download your Package",
  "opportunities": "Opportunities",
  "buyPackageDesc": "If you want to change your package, simply return to the previous page and make the necessary adjustments. All packages are downloadable and ready for use. You can change your package at any time within the settings of the SAAS desktop download.",
  "livePublishableKey": "Live publishable key",
  "livePublishableKeyHint": "Only values starting with \"pk_live_\" will be saved",
  "liveSecretKey": "Live secret key",
  "liveSecretKeyHint": "Only values starting with \"sk_live_\" or \"rk_live_\" will be saved",
  "enable": "Enable",
  "stripeSettings": "Stripe Gateway Settings",
  "typeAddonsPackage": "Type addon package",
  "isAddon": "Is addon",
  "addonPackageSetting": "Addon package setting",
  "serviceQuota": "Service quota",
  "lifetimeLabel": "Lifetime (Number of months, how long the package will be valid)",
  "lifetimeMonth": "Lifetime(month)",
  "availableRequestCount": "Available request count",
  "paymentErrorTitle": "Payment error",
  "purchaseServerErrorCode500": "An error occurred during payment. Please try to make the payment again later",
  "createOrderError": "Something went wrong while creating the order. Please try again later",
  "paymentServerErrorText": "Your payment did not go through due to a technical error from the bank. To resolve the issue, please confirm the transaction from HOLA in your banking app or on the website. If the problem persists, contact your bank or our support service.",
  "request": "Request",
  "expiredDate": "Expired date",
  "navigationRequests": "Requests",
  "serviceStore": "Store",
  "freeRequestCount": "Free request count",
  "payAndSend": "Pay & Send",
  "customerName": "Customer name",
  "requestType": "Request type",
  "yourRequests": "Your requests",
  "ourServices": "Our services",
  "attachSaleManager": "Attach a sale manager",
  "distributorWarningText": "We recommended to assign your local distributor before activating the bot. This will help ensure efficient operation. Thank you for your understanding.",
  "assign": "Assign",
  "appoint": "Appoint",
  "assignDistributor": "Assign Distributor",
  "distributor": "Distributor",
  "addSyncAccount": "Add Sync Account",
  "bank": "Bank",
  "addressNumber": "Address {n}",
  "checkboxBankAccountAgreement": "I authorize dispersion saas to save my bank account info in the Ventrix dispersion saas database for future use. I confirm that I am giving <a href='{siteUrl}' target='_blank'>{siteName}</a> a dispersion sass company permission to send, manage or change electronic payments to my account.",
  "walletWithdrawal": "Withdrawal",
  "fieldPhoneLabel": "Phone",
  "withdrawalHistory": "Withdrawal history",
  "vendorBasics": "Vendor basics",
  "bankInfo": "Bank info",
  "beneficiaryInfo": "Beneficiary info",
  "bankAccount": "Bank account",
  "payPalAccount": "PayPal account",
  "payoneerAccount": "Payoneer account",
  "stripeAccount": "Stripe account",
  "deleteStripeCredentials": "Delete stripe credentials",
  "withdrawalWarning": "To withdraw funds, please check your profile and fill in all the necessary information. This will help avoid potential issues and ensure successful completion of the transaction. Thank you for your understanding and cooperation!",
  "fillProfile": "Fill profile",
  "validationError_phone_exists": "This phone already use",
  "validationError_phone_invalid": "Invalid phone number",
  "validationError_phone_required": "Fill out this field",
  "notAuthForView": "Not Authorized to view",
  "projectScope": "Project Scope",
  "deadline": "Due Date",
  "budget": "Budget",
  "previousTicketId": "Preview Ticket",
  "issueCategory": "Category issue",
  "goalConversionRate": "Goal conversation rate",
  "preferredDateTime": "Preferred datetime",
  "currentHostingProvider": "Current hosting provider",
  "domainName": "Domain name",
  "consultationType": "Consultation type",
  "specificQuestions": "Specific questions",
  "currentConversionRate": "Current conversion rate",
  "campaignGoals": "Campaign goals",
  "messengers": "Messengers",
  "desiredStartDate": "Desired StartDate",
  "targetAudience": "Target audience",
  "websiteUrl": "Website URL",
  "paypalEmail": "Paypal email",
  "withdrawalMethod": "Withdrawal method",
  "accountHolderName": "Account holder name",
  "zipCode": "Zip code",
  "city": "City",
  "address1": "Address 1",
  "address2": "Address 2",
  "countryId": "Country",
  "ibanSwift": "IBAN/Swift",
  "payToName": "Pay to name",
  "accountNumber": "Account number",
  "stateProvince": "State/Province",
  "bankBranchCode": "Bank branch code",
  "beneficiaryName": "Beneficiary name",
  "agreementAccepted": "Agreement accepted",
  "ibanNumber": "IBAN",
  "bankAddress": "Bank address",
  "swiftBic": "SWIFT (BIC)",
  "bankName": "Bank name",
  "country": "Country",
  "payoneerEmail": "Payoneer email",
  "walletCommissions": "Commissions",
  "feedback": "Feedback",
  "pay": "Pay",
  "yourCommissions": "Your commissions",
  "tipsFrozenAccountWithoutPayment": "This is your only notice of processing fees owed. We will attempt to deduct these fees from your outstanding commission balance. If the balance exceeds $25 and becomes past due, your account may be suspended. Please settle the balance in full before or on the deadline date",
  "commissionHistory": "Commission history",
  "paymentDate": "Payment Date",
  "waSuccessConnectionWarning": "Your WhatsApp account will be available for use within 5 minutes.",
  "paymentMethod": "Payment method",
  "cash": "Cash",
  "cashDebit": "Cash Debit",
  "card": "Credit/Debit Card",
  "contactMe": "Contact Me",
  "processing": "Processing",
  "accountant": "Accountant",
  "requestAmnesty": "Request amnesty or Postpone payment",
  "requestFullExemption": "Request full exemption",
  "requestDeferralDays": "Deferral days",
  "amnestyHistory": "Amnesty history",
  "requestDate": "Request date",
  "ventrixSupport": "Ventrix Support",
  "training": "Training",
  "manualLink": "Manual Link",
  "videoLink": "Video Link",
  "help": "Help",
  "image": "Image",
  "messageWasRemoved": "This message was removed",
  "video": "Video",
  "unsupportedMessageType": "This message type is not supported yet",
  "uploadFileDialog": "Upload File",
  "upload": "Upload",
  "crmChat": "CRM Chat",
  "startChatHint": "Choose a chat to start the conversation.",
  "edited": "Edited",
  "allChats": "All Chats",
  "createFilter": "Create filter",
  "updateFilter": "Update filter",
  "filterName": "Filter name",
  "deleteFilter": "Delete filter",
  "addToFilter": "Add to filter",
  "addChatToFilter": "Add chat to filter",
  "newOrder": "New order",
  "goToOrders": "Go to orders",
  "quantities": "Quantities",
  "sku": "SKU",
  "selectAttribute": "Select option",
  "enterAttributeName": "Enter option name",
  "inventoryControl": "Inventory Control",
  "supportSettings": "Support Settings",
  "createConnectionSupport": "Create support connection",
  "supportConnections": "Support connections",
  "createConnectionDriver": "Create driver connection",
  "driverConnection": "Driver connection",
  "selectDriverTemplate": "Select driver bot template",
  "allLinkCategory": "Single-Link Catalog Generation",
  "permissionsTable": "Permissions table",
  "notifications": "Notifications",
  "relatedAssetsId": "Related Assets",
  "driverId": "Driver",
  "revertInventory": "Revert Inventory",
  "haveNoAccessToCRMChat": "To gain access to live support and CRM chat functionality, you need to upgrade your current package as the basic license does not include these features. By upgrading, you can enjoy the benefits of real-time customer support and enhanced customer relationship management capabilities.",
  "supportRequest": "Support request",
  "extended": "Extended",
  "landscapePhoto": "Landscape photo",
  "aboutUs": "About Us",
  "policy": "Policy",
  "success": "Success",
  "declined": "Declined",
  "adminLabel": "Admin",
  "navigationDispute": "Disputes",
  "userFullName": "Customer full name",
  "issueType": "Issue type",
  "users": "Users",
  "adminControls": "Admin controls",
  "owner": "Owner",
  "useCustomOptions": "Use custom option",
  "admins": "Administrators",
  "copyProduct": "Copy Product",
  "roleAssignConflictText1": "Error: Email Already Assigned",
  "roleAssignConflictText2": "The email address provided is already assigned to another user on your license.",
  "roleAssignConflictText3": "To change the user's access role, use the \"Force Update\" button. Please note that all previous profile data will be deleted and replaced with the new parameters.",
  "profileUniversal": "Profile(s)",
  "forceUpdate": "Force Update",
  "userDelete": "User Delete",
  "removeUserGloballyFromProfilesAndBranches": "Remove this user from all profiles and all branches",
  "profileDelete": "Delete profile",
  "profileCreate": "Create profile",
  "profileEdit": "Edit profile",
  "profileDeleteRestrictionTitle": "Profile Deletion Restrictions",
  "profileDeleteRestrictionText1": "This profile contains historical data and order history that are crucial for maintaining system integrity and tracking records. Deleting this profile is not permitted as it would affect related data and system functions.",
  "profileDeleteRestrictionText2": "Profile Deletion Allowed Only If: There is no activity, historical data, or order history associated with the profile.",
  "profileDeleteRestrictionText23": "If the Profile Has Activity: Please contact support for assistance with managing the profile data without deletion.\n",
  "profileDeleteRestrictionText3": "Thank you for your understanding and cooperation.",
  "driverAssignment": "Driver Assignment",
  "orderCreateTitle": "Create Order",
  "addToOrder": "Add to order",
  "searchByName": "Search by name",
  "searchByCategory": "Search by category",
  "searchByHashtags": "Search by hashtag",
  "filters": "Filters",
  "currentOrder": "Current Order",
  "customerEmail": "Customer Email",
  "customerPhone": "Customer Phone",
  "discountCode": "Discount code",
  "selectBranch": "Select branch",
  "usePickUp": "Use pickup",
  "selectLocation": "Select Location",
  "placeOrder": "Place Order",
  "timeDimension": "Time dimension",
  "minutes": "Minutes",
  "hours": "Hours",
  "days": "Days",
  "required": "Required",
  "selectAdditionalOptions": "Select Additional Options",
  "emptyCartState": "Products added to the order will be displayed here",
  "orderDiscount": "Order discount",
  "totalPrice": "Total price",
  "customerInfo": "Customer info",
  "delivery": "Delivery",
  "paymentMode": "Payment mode",
  "pointOfSale": "Point of sale",
  "on": "On",
  "off": "Off",
  "availableProductCount": "Available product count",
  "OrderStatus_0": "New review",
  "OrderStatus_1": "Review Order",
  "OrderStatus_2": "Cannot Process Order",
  "OrderStatus_3": "Modify Order",
  "OrderStatus_4": "Awaiting Confirmation",
  "OrderStatus_5": "Cancel Order",
  "OrderStatus_6": "Complete",
  "OrderStatus_7": "Finalize",
  "OrderStatus_8": "Accept Order",
  "OrderStatus_9": "Failed Payment",
  "OrderStatus_0_past": "New reviewed",
  "OrderStatus_1_past": "Order Reviewed",
  "OrderStatus_2_past": "Order Cannot Process",
  "OrderStatus_3_past": "Order Modified",
  "OrderStatus_4_past": "Order Confirmed",
  "OrderStatus_5_past": "Order Canceled",
  "OrderStatus_6_past": "Order Completed",
  "OrderStatus_7_past": "Order Finalized",
  "OrderStatus_8_past": "Order Accepted",
  "OrderStatus_9_past": "Failed Payment",
  "OrderPaymentStatus_0": "Pending",
  "OrderPaymentStatus_1": "Confirmed",
  "OrderPaymentStatus_2": "Rejected",
  "OrderPaymentStatus_3": "Cancelled",
  "OrderPaymentStatus_4": "Refunded",
  "OrderPaymentStatus_5": "User ready to pay",
  "OrderPaymentStatus_6": "Refund Initiated",
  "OrderPaymentStatus_7": "Refund Processed",
  "PreDeliveryStatus_0": "Status Not Set",
  "PreDeliveryStatus_1": "Start Order Preparation",
  "PreDeliveryStatus_2": "Order Preparation Completed",
  "PreDeliveryStatus_3": "Awaiting Driver Assignment",
  "PreDeliveryStatus_4": "Driver Assigned",
  "PreDeliveryStatus_5": "No Driver Confirmation Received",
  "PreDeliveryStatus_6": "Driver Confirmed",
  "PreDeliveryStatus_7": "Driver Declined Pickup",
  "PreDeliveryStatus_8": "Problem with Order",
  "PreDeliveryStatus_0_past": "Status Not Set",
  "PreDeliveryStatus_1_past": "Order Preparation Started",
  "PreDeliveryStatus_2_past": "Order Preparation Completed",
  "PreDeliveryStatus_3_past": "Awaiting Driver Assignment",
  "PreDeliveryStatus_4_past": "Driver Assigned",
  "PreDeliveryStatus_5_past": "No Driver Confirmation Received",
  "PreDeliveryStatus_6_past": "Driver Confirmed",
  "PreDeliveryStatus_7_past": "Driver Declined Pickup",
  "PreDeliveryStatus_8_past": "Problem with Order",
  "DeliveryStatus_0": "Status Not Set",
  "DeliveryStatus_1": "In Route to pickup",
  "DeliveryStatus_2": "Arrived to pickup",
  "DeliveryStatus_3": "En route",
  "DeliveryStatus_4": "Delayed",
  "DeliveryStatus_5": "Problem",
  "DeliveryStatus_6": "Waiting on customer",
  "DeliveryStatus_7": "Delivered",
  "DeliveryStatus_8": "Cancel",
  "DeliveryStatus_0_past": "Status Not Set",
  "DeliveryStatus_1_past": "In Route to pickup",
  "DeliveryStatus_2_past": "Arrived to pickup",
  "DeliveryStatus_3_past": "En route",
  "DeliveryStatus_4_past": "Delayed",
  "DeliveryStatus_5_past": "Problem",
  "DeliveryStatus_6_past": "Waiting on customer",
  "DeliveryStatus_7_past": "Delivered",
  "DeliveryStatus_8_past": "Canceled",
  "statistic": "Statistic",
  "statisticText1": "Our Statistics Tab is designed to provide you with comprehensive and actionable insights based on your sales data. To ensure the accuracy and relevance of the information displayed, the Statistics Tab will become available after a minimum of 200 to 500 orders have been processed. This threshold allows us to generate meaningful and statistically significant data that can better inform your business decisions.",
  "statisticText2": "We appreciate your understanding and patience as we gather sufficient data to deliver the best possible insights for your business growth.",
  "pointOfSaleProvider": "Point of Sales",
  "apiDocuments": "Api documents",
  "yourApiData": "Your API data",
  "accessToken": "Access token",
  "userId": "User id",
  "businessId": "Business id",
  "profiles": "Profiles",
  "whiteLabelDescription1": "Thank you for choosing Ventrix Natural Conversation to represent your company. Ventrix Natural Conversation leverages all your company's documentation and links, processing this information on the Ventrix Machine Learning Service platform. The more information and documentation you submit, the better our Machine Learning can understand, support, and sell your products and services.",
  "whiteLabelDescription2": "Our service typically takes 1-3 months to learn and understand your business thoroughly. By using traditional chatbot sales and statistics, we aim to enhance the support and sales of your business. To ensure a great start with Machine Learning, please provide all relevant information about your business and select the products and FAQs that best represent what you will be selling.",
  "facebookUrl": "Facebook URL",
  "instagramUrl": "Instagram URL",
  "tiktokUrl": "TikTok URL",
  "youtubeUrl": "Youtube URL",
  "vimeoUrl": "Vimeo URL",
  "linkedInUrl": "LinkedIn URL",
  "dragDropFile": "Drag and drop file",
  "selectProductsTemplate": "Select products template",
  "selectFaqTemplate": "Select FAQ template",
  "timelineLabel": "Learning Status",
  "start": "Start",
  "halfwayThere": "Halfway There",
  "systemReady": "System Ready",
  "uploadDocsLabel": "Add documents, pictures, ads., sales training etc",
  "isNaturalConversationBot": "Use Natural Coversation Bot Ventrix",
  "restrictedZones": "Restricted zones",
  "markerLabelTooltip": "Create Delivery Radius",
  "polygonLabelTooltip": "Create Restricted Zone",
  "zone": "Zone",
  "customerPickup": "Customer Pickup",
  "allowCache": "Cash",
  "allowDebitCredit": "Credit/Debit Card",
  "allowInsurance": "Insurance",
  "allowRewards": "Rewards Card",
  "reward": "Reward",
  "insurance": "Insurance",
  "change": "Change",
  "availabilityToUsePostOfSale": "Use Point of Sale for this Branch",
  "selectAvailablePaymentMethods": "Available Payment Options for this Branch",
  "notAvailablePaymentMethod": "This option is currently unavailable because your API is not connected. Please connect your API to enable this payment method. For more information, visit the License Support tab.",
  "changeProvided": "Change provided",
  "changeReturned": "Change returned",
  "yourSalesInfo": "Your sales manager info",
  "of": "of",
  "connect": "Connect",
  "connectionLimitTitle": "Connection Limit Reached",
  "connectionLimitDesc": "You have reached the maximum number of connections allowed by your current package. To add more connections, please upgrade your package or adjust your settings in the License tab. We apologize for any inconvenience this may have caused.",
  "images": "Images",
  "unlimited": "Unlimited",
  "sourceOfFunds": "Source of funds",
  "licenseHolder": "License holder",
  "allTransactions": "All transactions",
  "branches": "Branches",
  "youCantRegisterWithoutSaleRepr": "You can't registration without Sale Representative",
  "orderHistory": "Cash order history",
  "commissionAmount": "Commission Amount",
  "seeTransactions": "Show Orders",
  "totalAmount": "Order amount",
  "cashCommissions": "Cash Commissions",
  "cashOrders": "Cash Orders",
  "availableForPayment": "Available for payment from",
  "paid": "Paid",
  "pending": "Pending",
  "cashFeesText": "Cash processing fees from {from} to {to}",
  "cashFeesWarning1": "You have received payment(s) for order(s) through the \"Cash\" payment method. As per the License terms of service, a commission fee is charged for each order paid in \"Cash\" at the agreed rate outlined in the terms of service.",
  "cashFeesWarning2": "This commission is due upon receipt. Please make the payment as soon as possible to avoid any disruption to your License. You may or may not receive additional warnings regarding this matter. If you have any issues, please contact support.",
  "cashCommissionPayment": "Cash commission payment",
  "assigned": "Assigned",
  "changedBy": "Changed by",
  "changeOperator": "Change Operator",
  "currentOperator": "Current operator",
  "annual": "Annual",
  "monthly": "Monthly",
  "perMonth": "/Per Month",
  "subscriptionStatus": "Subscription status",
  "subscriptionStatus_payment_error": "There was an error processing your subscription.",
  "subscriptionStatus_success_buy": "Subscription successful!",
  "subscriptionStatus_cancel_subscribe": "We're sorry to see you downgrade. Your subscription will remain active until {date}.",
  "machineLearning": "Machine Learning",
  "subscriptionStatusTitle_payment_error": "Subscription Error",
  "subscriptionStatusTitle_success_buy": "Subscription Buy",
  "subscriptionStatusTitle_cancel_subscribe": "Subscription Cancellation",
  "cancelSubscription": "Cancel Subscription",
  "cancelSubscriptionAsk": "Are you sure want to cancel your subscription?",
  "payment_status_0": "Failed",
  "payment_status_1": "Confirmed",
  "payment_status_2": "Rejected",
  "payment_status_3": "Cancelled",
  "payment_status_4": "Refunded",
  "payment_status_5": "User ready to pay",
  "failed": "Failed",
  "master_package_fund": "Commissions Payout: Annual Package Purchase, User: {senderName}",
  "sales_representative_package_fund": "You received an amount of ${amount} from your license holder({senderName}) for purchasing package {packageName}",
  "license_holder_package_fund": "Package Purchase {packageName}",
  "branch_product_fund": "You have been paid ${amount} from {senderName} for order {orderId}",
  "branch_product_refund": "Order {orderId} was canceled, ${amount} was refunded to the client {senderName}"
}
