import $http from '@/utils/https';

const API_URL = `${process.env.VUE_APP_APIGW_URL}/chatbots/session/workspace`;

export default {
  async fetch() {
    const response = await $http(API_URL);
    return response.data.data;
  },
  async update({workspaceId}) {
    const response = await $http.post(`${API_URL}${workspaceId ? `?workspace_id=${workspaceId}` : ''}`, {});
    return response.data.data;
  }
};
